import { Pipe, PipeTransform } from '@angular/core';

import { pluralize } from '@shared/utils/pluralize.util';

@Pipe({
  name: 'pluralize'
})
export class PluralizePipe implements PipeTransform {

  words = {
    meals: {
      singular: 'прием',
      pluralSmall: 'приема',
      pluralBigger: 'приемов'
    },
    dishes: {
      singular: 'блюдо',
      pluralSmall: 'блюда',
      pluralBigger: 'блюд'
    },
    dishes_genitive: {
      singular: 'блюда',
      pluralSmall: 'блюд',
      pluralBigger: 'блюд'
    },
    days: {
      singular: 'день',
      pluralSmall: 'дня',
      pluralBigger: 'дней'
    }
  };

  transform(num: number, word: 'meals' | 'dishes' | 'days' | 'dishes_genitive'): unknown {
    return pluralize(num, this.words[word].singular, this.words[word].pluralSmall, this.words[word].pluralBigger);
  }

}
