<div class="wrapper" [class.multi-dish]="isMultiply">
  <div *ngIf="grams" class="item">
    <span>Грамм</span>
    <b>{{ grams }}</b>
  </div>
  <div class="item">
    <span>Калории</span>
    <b>{{ caloricity }} ккал</b>
  </div>
  <div class="item">
    <span>Белки</span>
    <b>{{ proteins }}</b>
  </div>
  <div class="item">
    <span>Жиры</span>
    <b>{{ fats }}</b>
  </div>
  <div class="item">
    <span>Углеводы</span>
    <b>{{ carbs }}</b>
  </div>
</div>
