enum ActionTypes {
  LOAD_MINIMUM_PRICES = '[Global] Load minimum prices',
  HANDLE_ROUTER_EVENTS = '[Global] Handle router events',
}

export class LoadMinimumPrices {
  static readonly type = ActionTypes.LOAD_MINIMUM_PRICES;
}

export class HandleRouterEvents {
  static readonly type = ActionTypes.HANDLE_ROUTER_EVENTS;
}
