import { createSelector, Selector } from '@ngxs/store';

import { LoadingTagEnum } from '@shared/enums';
import { LoadingStateModel } from './loading.model';

export class LoadingSelectors {
  @Selector([state => state.loading])
  static state(state: LoadingStateModel): LoadingStateModel {
    return state;
  }

  static isLoading(loadingTag: LoadingTagEnum) {
    return createSelector([LoadingSelectors.state], (state: LoadingStateModel) => {
      return state && state[loadingTag];
    });
  }
}
