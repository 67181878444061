<app-modal-layout (onGoBack)="closeModal()" (onClose)="closeModal()">
  <app-icon icon="modal-close" class="icon-close" (click)="closeModal()"></app-icon>

  <ng-container *ngIf="!isFromMenuExample; else menuExample">
    <div class="dish-details">
      <div class="img-block">
        <app-dish-img [dishId]="!isArray(dish.id) ? dish.id : dish?.id[0]" [containerType]="dish?.containerType"></app-dish-img>
      </div>

      <div class="dish-details__content">
        <div class="dish-details__header">
          <div class="menu-type" *ngIf="dish?.mealType">{{ ruDishTypeEnum[dish?.mealType] }}</div>
          <div class="calories" *ngIf="!isArray(dish?.grams)">{{ dish?.grams }} г / {{ dish?.caloricity }} <span>ккал</span></div>
        </div>

        <div class="dish-title">{{ dish.title }}</div>

        <div *ngIf="!isArray(dish.id); else multiply" class="dish-tooltip">
          <app-dish-params
            [caloricity]="dish.caloricity"
            [proteins]="dish.proteins"
            [fats]="dish.fats"
            [carbs]="dish.carbs"
          ></app-dish-params>

          <div class="dish-tooltip-content">
            <div class="content-title">Ингредиенты</div>
            {{content}}
          </div>
        </div>

        <ng-template #multiply>
          <div class="multi-dish-wrapper">
            <app-dish-params
              *ngFor="let id of dish?.id; let i = index"
              [grams]="dish?.grams[i][id]"
              [caloricity]="dish?.caloricity[i][id]"
              [proteins]="dish?.proteins[i][id]"
              [fats]="dish.fats[i][id]"
              [carbs]="dish.carbs[i][id]"
              [isMultiply]="true"
            ></app-dish-params>
          </div>
        </ng-template>

        <app-select-dish-modal
          [dish]="dish"
          [package]="package"
          [isFromAdditionModal]="isFromAdditionModal"
          [isFromDetailsModal]="true"
          [isFromSelectDishModal]="isFromSelectDishModal"
          (undoSelect)="deselect(dish)"
          (rateDish)="setDishRating(dish, package)"
        ></app-select-dish-modal>
      </div>
    </div>
  </ng-container>

  <ng-template #menuExample>
    <div class="dish-details">
      <div class="img-block">
        <app-dish-img [dishId]="menuExampleDish?.code" [containerType]="menuExampleDish?.containerType"></app-dish-img>
      </div>

      <div class="dish-details__content">
        <div class="dish-details__header">
          <div class="menu-type" *ngIf="menuExampleDish?.type">{{ ruDishTypeEnum[menuExampleDish?.type] }}</div>
          <div class="calories">{{ menuExampleDish?.weight }} г / {{ menuExampleDish?.calories }} <span>ккал</span></div>
        </div>

        <div class="dish-title">{{menuExampleDish.name}}</div>

        <div class="dish-tooltip">
          <app-dish-params
            [caloricity]="menuExampleDish.calories"
            [proteins]="menuExampleDish.proteins"
            [fats]="menuExampleDish.fat"
            [carbs]="menuExampleDish.carbohydrates"
          ></app-dish-params>

          <div class="dish-tooltip-content">
            <div class="content-title">Ингредиенты</div>
            {{ menuExampleContent }}
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</app-modal-layout>
