import { PackageStateEnum } from '@shared/enums';
import { ClientSubscriptionPackage } from '@shared/models';

/**
 * Метод проверяет есть ли еще свободные слоты в пакете для добавления дополнительного блюда
 * @param activePackage текущий активный пакет подписки
 */
export function getAdditionalStatus({
  hasCustomization,
  isCustomizable,
  menuItems,
  availableAdditionsCount,
  packageState,
  hasConfirmedCustomization,
}: ClientSubscriptionPackage): boolean {
  const addedDishesCount = menuItems?.filter(({ isAdded }) => isAdded).length || 0;
  const isAdditionsLimitReached = availableAdditionsCount - addedDishesCount <= 0;
  const status =
    packageState !== PackageStateEnum.onCooking &&
    isCustomizable &&
    !hasCustomization &&
    !hasConfirmedCustomization &&
    !isAdditionsLimitReached;

  return status;
}
