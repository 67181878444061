import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customSize',
})
export class CustomSizePipe implements PipeTransform {
  // Список блюд, фото которых нужно уменьшить на 30%
  codeList = ['2', '5', '6', '7', '8'];

  transform(value: string): object {
    return this.codeList.includes(value) ? { transform: 'scale(0.7)' } : {};
  }
}
