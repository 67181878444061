import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@shared/shared.module';
import { containers, IntroContainerComponent } from './containers';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: IntroContainerComponent,
    }
];

@NgModule({
    declarations: [
        ...containers
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule.forChild(routes),
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class IntroModule { }
