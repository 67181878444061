/* eslint-disable no-underscore-dangle */

import { Input, Output, EventEmitter, Self, Component } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  template: ''
})
export class BaseControlComponent<T> implements ControlValueAccessor {
  private _value: T;
  disabled = false;
  isRequired = false;

  get value(): T {
    return this._value;
  }

  @Input()
  set value(value: T) {
    if (value !== this._value) {
      this._value = value;

      this.onChange(value);
      this.change.emit(value);
      this.onTouched();
    }
  }

  @Input() label: string;
  @Input() ariaLabel: string;
  @Input() placeholder: string;
  @Input() tabindex: number;
  @Input() errorMessages: { [key: string]: string } = {};

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new EventEmitter<T>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (_value: T) => {};
  onTouched = () => {};

  constructor(
    @Self() public ngControl: NgControl
  ) {
    ngControl.valueAccessor = this;
  }

  get hasErrors(): boolean {
    return !!(
      this.ngControl.control &&
      this.ngControl.control.touched &&
      this.ngControl.control.errors
    );
  }

  get errorMessage(): string {
    const errors = Object.keys(this.ngControl.control.errors || {});
    const hasError = errors && errors.length;

    if (!hasError) {
      return '';
    }

    return errors.reduce((res: string, key: string) => {
      if (this.errorMessages[key] && !res) {
        res = this.errorMessages[key];
      }
      return res;
    }, '');
  }

  get hasValue(): boolean {
    if (typeof this.value === 'string') {
      return this.value && this.value.length > 0;
    }

    return !!this.value;
  }

  // implementation of `ControlValueAccessor`
  writeValue(value: T): void {
    this._value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
