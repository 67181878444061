import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { take } from 'rxjs/operators';
import { Preferences } from '@capacitor/preferences';

import { environment } from 'src/environments/environment';
import { EventPayload, EventProperties } from '@shared/models';
import { ProfileSelectors } from '@store/profile';
import packageData from 'package.json';
import { LocalStorageKeysEnum } from '@shared/enums';

@Injectable({
  providedIn: 'root',
})
export class RudderStackService {
  constructor(private http: HttpClient, private store: Store) {}

  async track(eventName: string, properties?: EventProperties) {
    const url = `${environment.url.rudderstack}/track`;
    const anonymousId = await this.getAnonymousId();
    const profile = this.store.selectSnapshot(ProfileSelectors.profile);

    const userId = new URL(document.URL).searchParams.get('clientGuid') || profile?.id || null;

    if (anonymousId === null && userId === null) {
      return;
    }

    const requestPayload = this.generateRequestPayload(userId, anonymousId, eventName, properties);

    this.http.post(url, requestPayload).pipe(take(1)).subscribe();
  }

  async getAnonymousId(): Promise<string> {
    const data = await Preferences.get({
      key: LocalStorageKeysEnum.visitorId,
    });
    return data?.value;
  }

  private generateRequestPayload(userId: string, anonymousId?: string, eventName?: string, properties?: EventProperties): EventPayload {
    return {
      anonymousId,
      userId,
      eventName,
      properties,
      context: {
        userAgent: `PE MobileApp ${packageData.version}`,
      },
      integrations: {
        All: true,
      },
    };
  }
}
