<swiper-container #swiper [pagination]="true" [speed]="400" (swiperslidechange)="checkLastSlide()">
  <swiper-slide>
    <div class="image type-1"></div>

    <div class="content">
      <div class="container">
        <div class="title">Всегда готово<br />и под рукой</div>
        <div class="text">Можно забыть о списке продуктов,<br />кухне и грязной посуде</div>
        <div class="buttons">
          <button (click)="next()" class="btn-base btn-next on-activatable">Класс</button>

          <a class="link-base link-skip" (click)="start()">Пропустить</a>
        </div>
      </div>
    </div>
  </swiper-slide>
  <swiper-slide>
    <div class="image type-2"></div>

    <div class="content">
      <div class="container">
        <div class="title">Три, четыре<br />или пять блюд в день</div>
        <div class="text">Подписка на три дня, две недели или<br />месяц. Чем дольше, тем дешевле</div>
        <div class="buttons">
          <button (click)="next()" class="btn-base btn-next on-activatable">Хорошо</button>

          <a class="link-base link-skip" (click)="start()">Пропустить</a>
        </div>
      </div>
    </div>
  </swiper-slide>
  <swiper-slide>
    <div class="image type-3"></div>

    <div class="content">
      <div class="container">
        <div class="title">Бесплатная замена<br />блюд</div>
        <div class="text">Если не любите или не едите<br />какие-нибудь ингредиенты</div>
        <div class="buttons">
          <button (click)="next()" class="btn-base btn-next on-activatable">Супер</button>

          <a class="link-base link-skip" (click)="start()">Пропустить</a>
        </div>
      </div>
    </div>
  </swiper-slide>
  <swiper-slide>
    <div class="image type-4"></div>

    <div class="content">
      <div class="container">
        <div class="title">Доставка<br />по Москве</div>
        <div class="text">Два или три раза в неделю.<br />В двухчасовой интервал с 6:00 до 11:00</div>
        <div class="buttons">
          <button (click)="next()" class="btn-base btn-next on-activatable">Ясно</button>

          <a class="link-base link-skip" (click)="start()">Пропустить</a>
        </div>
      </div>
    </div>
  </swiper-slide>
  <swiper-slide>
    <div class="image type-5"></div>

    <div class="content">
      <div class="container">
        <div class="title">Безлимитная<br />заморозка</div>
        <div class="text">Приостановите подписку до 12:00<br />накануне дня доставки. Это бесплатно</div>
        <div class="buttons">
          <button (click)="next()" class="btn-base btn-next on-activatable">Иду заказывать</button>
        </div>
      </div>
    </div>
  </swiper-slide>
</swiper-container>
