import { Injectable } from '@angular/core';
import { CanMatch, Router } from '@angular/router';
import { Preferences } from '@capacitor/preferences';

import { LocalStorageKeysEnum } from '@shared/enums';

@Injectable({
  providedIn: 'root'
})
export class IntroGuard implements CanMatch {

  constructor(
    private route: Router,
  ) {}

  async canMatch(): Promise<boolean> {
    const hasSeenIntro = await Preferences.get({
      key: LocalStorageKeysEnum.introSeen
    });

    if (hasSeenIntro && hasSeenIntro.value === 'true') {
      return true;
    }

    this.route.navigateByUrl('/intro', { replaceUrl: true });
    return true;
  }

}
