import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class RedirectByFormService {

  post(formInputsObj: object, targetUrl: string, formTarget: '_blank' | '_self' | '_parent' | '_top'): void {
    // Don't use in this method DOCUMENT from @angular/core
    // or windowRefService.nativeWindow.document from WindowRefService
    // instead of native document.
    // Because of this, payment by a new card with confirmation will not work
    const mapForm = document.createElement('form');

    mapForm.target = formTarget;
    mapForm.method = 'POST';
    mapForm.action = targetUrl;

    Object.keys(formInputsObj).forEach(param => {
      const mapInput = document.createElement('input');

      mapInput.type = 'hidden';
      mapInput.name = param;
      mapInput.setAttribute('value', formInputsObj[param]);
      mapForm.appendChild(mapInput);
    });

    document.body.appendChild(mapForm);
    mapForm.submit();
  }

}
