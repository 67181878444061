import dayjs from 'dayjs';

import { getMonthName } from '@shared/utils';
import { ClientSubscriptionPackage } from '@shared/models';
import { PackageCustomizations } from '@modules/client-menu/models';

interface DateItem {
  month: string;
  days: number[]
}

function getDatesMap(dates: string[]): Record<string, DateItem> {
  return dates.reduce((res, date) => {
    const month = parseInt(dayjs(date).format('M'), 10);
    const day = parseInt(dayjs(date).format('D'), 10);

    if (!res[month]) {
      res[month] = {
        month: getMonthName(month),
        days: [day],
      };
    } else {
      res[month] = {
        ...res[month],
        days: [...res[month].days, day]
      };
    }
    return res;
  }, {});
}

function getPackageDates(packages: ClientSubscriptionPackage[] | PackageCustomizations[]): string[] {
  return packages.map((item) => item?.date || item?.packageDate);
}

export const getDatesFromPackages = (packages: ClientSubscriptionPackage[] | PackageCustomizations[]): string => {
  const dates = getPackageDates(packages);
  const datesMap = getDatesMap(dates);

  return Object.values(datesMap)
    .map((item) => {
      const days = item.days.sort((a, b) => a - b).join(', ');

      return `${days} ${item.month}`;
    })
    .join(', ');
};
