import { ProgramViewAvailableData } from '@shared/models';
import { ProgramDurationsEnum, ProgramCaloriesEnum } from '@shared/enums';

export const ALL_PROGRAMS_VIEW_AVAILABLE_DATA: Record<string, ProgramViewAvailableData> = {
  [ProgramCaloriesEnum.cal1200]: {
    durationOptions: [
      {
        id: ProgramDurationsEnum.oneWeek,
      },
      {
        id: ProgramDurationsEnum.twoWeeks,
      },
      {
        id: ProgramDurationsEnum.fourWeeks,
      },
    ],
    extOptions: {
      eatOnSaturdayAndSunday: true,
    },
  },
  [ProgramCaloriesEnum.cal1500]: {
    durationOptions: [
      {
        id: ProgramDurationsEnum.oneWeek,
      },
      {
        id: ProgramDurationsEnum.twoWeeks,
      },
      {
        id: ProgramDurationsEnum.fourWeeks,
      },
    ],
    extOptions: {
      eatOnSaturdayAndSunday: true,
    },
  },

  [ProgramCaloriesEnum.cal2000]: {
    durationOptions: [
      {
        id: ProgramDurationsEnum.oneWeek,
      },
      {
        id: ProgramDurationsEnum.twoWeeks,
      },
      {
        id: ProgramDurationsEnum.fourWeeks,
      },
    ],
    extOptions: {
      eatOnSaturdayAndSunday: true,
    },
  },

  [ProgramCaloriesEnum.cal2500]: {
    durationOptions: [
      {
        id: ProgramDurationsEnum.oneWeek,
      },
      {
        id: ProgramDurationsEnum.twoWeeks,
      },
      {
        id: ProgramDurationsEnum.fourWeeks,
      },
    ],
    extOptions: {
      eatOnSaturdayAndSunday: false,
    },
  },
};

export const getProgramViewAvailableData = (caloricity: ProgramCaloriesEnum) => ALL_PROGRAMS_VIEW_AVAILABLE_DATA[caloricity];
