export enum DaysOfWeekEnum {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday'
}

export const RuShortDaysOfWeekEnum = {
  [DaysOfWeekEnum.Monday]: 'Пн',
  [DaysOfWeekEnum.Tuesday]: 'Вт',
  [DaysOfWeekEnum.Wednesday]: 'Ср',
  [DaysOfWeekEnum.Thursday]: 'Чт',
  [DaysOfWeekEnum.Friday]: 'Пт',
  [DaysOfWeekEnum.Saturday]: 'Сб',
  [DaysOfWeekEnum.Sunday]: 'Вс'
};

export const RuFullDaysOfWeekEnum = {
  [DaysOfWeekEnum.Monday]: 'Понедельник',
  [DaysOfWeekEnum.Tuesday]: 'Вторник',
  [DaysOfWeekEnum.Wednesday]: 'Среда',
  [DaysOfWeekEnum.Thursday]: 'Четверг',
  [DaysOfWeekEnum.Friday]: 'Пятница',
  [DaysOfWeekEnum.Saturday]: 'Суббота',
  [DaysOfWeekEnum.Sunday]: 'Воскресенье'
};

export const WeekdayNumbersEnum = {
  [DaysOfWeekEnum.Monday]: 1,
  [DaysOfWeekEnum.Tuesday]: 2,
  [DaysOfWeekEnum.Wednesday]: 3,
  [DaysOfWeekEnum.Thursday]: 4,
  [DaysOfWeekEnum.Friday]: 5,
  [DaysOfWeekEnum.Saturday]: 6,
  [DaysOfWeekEnum.Sunday]: 0
};

export enum IntWeekdaysToRussianEnum {
  'вс',
  'пн',
  'вт',
  'ср',
  'чт',
  'пт',
  'сб'
}

export enum IntWeekdaysToFullRussianGenEnum {
  'воскресенье',
  'понедельник',
  'вторник',
  'среду',
  'четверг',
  'пятницу',
  'субботу'
}

export enum IntWeekdaysToFullRussianEnum {
  'воскресенье',
  'понедельник',
  'вторник',
  'среда',
  'четверг',
  'пятница',
  'суббота'
}

export enum RuMonthsNamesEnum {
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря'
}

export enum RegionsEnum {
  MSK = 'MSK',
  MO = 'MO',
  SPB = 'SPB'
}
