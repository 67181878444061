import { ChangeDetectionStrategy, EventEmitter, Input, Output, Component } from '@angular/core';
import { NgOnDestroyService } from '@shared/services';

@Component({
  selector: 'app-top-toolbar',
  templateUrl: './top-toolbar.component.html',
  styleUrls: ['./top-toolbar.component.scss'],
  providers: [NgOnDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopToolbarComponent {
  @Input() title = '';
  @Output() goBack = new EventEmitter();

  onGoBack(): void {
    this.goBack.emit();
  }
}
