export enum MonthEnum {
  january = 1,
  february,
  march,
  april,
  may,
  june,
  july,
  august,
  september,
  october,
  november,
  december,
}

export const monthGenitiveDescription = {
  [MonthEnum.january]: 'января',
  [MonthEnum.february]: 'февраля',
  [MonthEnum.march]: 'марта',
  [MonthEnum.april]: 'апреля',
  [MonthEnum.may]: 'мая',
  [MonthEnum.june]: 'июня',
  [MonthEnum.july]: 'июля',
  [MonthEnum.august]: 'августа',
  [MonthEnum.september]: 'сентября',
  [MonthEnum.october]: 'октября',
  [MonthEnum.november]: 'ноября',
  [MonthEnum.december]: 'декабря'
};

export const monthDescription = {
  [MonthEnum.january]: 'январь',
  [MonthEnum.february]: 'февраль',
  [MonthEnum.march]: 'март',
  [MonthEnum.april]: 'апрель',
  [MonthEnum.may]: 'май',
  [MonthEnum.june]: 'июнь',
  [MonthEnum.july]: 'июль',
  [MonthEnum.august]: 'август',
  [MonthEnum.september]: 'сентябрь',
  [MonthEnum.october]: 'октябрь',
  [MonthEnum.november]: 'ноябрь',
  [MonthEnum.december]: 'декабрь'
};
