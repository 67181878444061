<div
  class="wrapper"
  *ngIf="{
  subscription: activeSubscriptionDetails$ | async,
  package: activePackage$ | async,
  additionDisablementStatus: additionDisablementStatus$ | async
} as data"
>
  <div class="container" *ngIf="(data.package?.packageId && data.package?.menuItems?.length > 0); else emptyList">
    <app-dish-list-header class="dish-header" [dishes]="data.package.menuItems"></app-dish-list-header>
    <div class="list">
      <app-dish-item
        class="dish-item"
        *ngFor="let dish of data.package.menuItems"
        [scrollableContainer]="scrollableContainer"
        [programType]="data.subscription?.menuType"
        [dish]="dish"
        [package]="data.package"
      ></app-dish-item>

      <div *ngIf="data.additionDisablementStatus" class="add-dish" (click)="openDishAdditionDialog(data.package)">
        <div>
          <app-icon icon="add-dish" class="add-icon"></app-icon>
          <div class="text">
            <p>Если вам захочется<br />что-то еще</p>
          </div>
        </div>
        <button class="btn btn--orange">Добавить</button>
      </div>
    </div>
  </div>

  <ng-template #emptyList>
    <div class="empty-list" *ngIf="data.package?.date">
      <i class="icon-empty"></i>
      Меню на {{ data.package?.date | date: 'd MMMM' }} еще недоступно
    </div>
  </ng-template>
</div>
