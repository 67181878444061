import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mealCount'
})
export class MealCountPipe implements PipeTransform {
  transform(value: number): string {
    if (!value) { return ''; }

    if (value === 1200) {
      return '3';
    }

    if (value === 1500) {
      return '4';
    }

    return value.toString();
  }
}
