import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Self,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import dayjs, { Dayjs } from 'dayjs';

import { NgOnDestroyService } from '@shared/services';
import { monthDescription, PackageStateEnum } from '@shared/enums';
import { capitalize, getRuDay } from '@shared/utils';
import { ClientSubscriptionPackage, DayItem } from '@shared/models';

@Component({
  selector: 'app-delivery-calendar',
  templateUrl: './delivery-calendar.component.html',
  styleUrls: ['./delivery-calendar.component.scss'],
  providers: [NgOnDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryCalendarComponent implements OnInit {

  @Input() packagesData: ClientSubscriptionPackage[];
  @Input() initialDate: Dayjs;
  @Input() highlightedDates: string[] = [];
  @Input() dateFilter = (date: Dayjs): boolean => !date;
  @Output() private dateClick = new EventEmitter<DayItem>();

  readonly packageStateEnum = PackageStateEnum;
  dates: DayItem[] = [];

  private readonly calendarDaysCountTarget = 42;

  private packages: ClientSubscriptionPackage[] = [];
  private currentDate: Dayjs;

  get month(): string {
    if (!this.currentDate) { return null; }

    return capitalize(monthDescription[this.currentDate.format('M')]);
  }

  get year(): string {
    return this.currentDate ? this.currentDate.format('YYYY') : null;
  }

  get showYear(): boolean {
    if (!this.currentDate) { return false; }

    return this.currentDate.year() !== dayjs().year();
  }

  constructor(
    @Self() private ngOnDestroy$: NgOnDestroyService
  ) {}

  ngOnInit(): void {
    this.initDates()
      .pipe(
        takeUntil(this.ngOnDestroy$)
      )
      .subscribe(() => {
        this.updateDates();
      });
  }

  previousMonth(): void {
    this.currentDate = this.currentDate.subtract(1, 'M');
    this.updateDates();
  }

  nextMonth(): void {
    this.currentDate = this.currentDate.add(1, 'M');
    this.updateDates();
  }

  handleDayClick(dayItem: DayItem): void {
    this.dateClick.emit(dayItem);
  }

  trackByFn(index: number, item: DayItem): string {
    return item?.date.toISOString() || `${index}`;
  }

  private initDates(): Observable<null> {
    this.packages = this.packagesData || [];

    const firstPackageDate = this.packagesData?.length > 0
      ? dayjs(this.packagesData[0].date)
      : null;

    const currentDate = this.isSubscriptionFinished(this.packagesData)
      ? firstPackageDate
      : this.initialDate || dayjs();
    this.currentDate = currentDate.clone();

    return of(null);
  }

  private isSubscriptionFinished(data: ClientSubscriptionPackage[] = []): boolean {
    if (data.length === 0) { return false; }

    const lastPackage = data[data.length - 1];
    const startOfToday = dayjs().startOf('day');

    return startOfToday > dayjs(lastPackage.date);
  }

  private updateDates(): void {
    const monthStart = this.currentDate.startOf('M');
    const monthEnd = this.currentDate.endOf('M');

    const previousMonthDates = this.getPreviousMonthDates(monthStart);
    const currentMonthDates = this.getMonthDates(monthStart, monthEnd, true);
    const previousDatesCount = previousMonthDates.length + currentMonthDates.length;
    const nextMonthDates = this.getNextMonthDates(monthEnd, previousDatesCount);

    this.dates = previousMonthDates.concat(currentMonthDates, nextMonthDates);
  }

  private getMonthDates(monthStart: Dayjs, monthEnd: Dayjs, isCurrentMonth = false): DayItem[] {
    const dates = [];

    for (let i = monthStart.get('date'); i <= monthEnd.get('date'); i++) {
      const date = monthStart.startOf('M').add(i - 1, 'day');
      dates.push(this.getDayItem(date, !isCurrentMonth));
    }

    return dates;
  }

  private getPreviousMonthDates(monthStart: Dayjs): DayItem[] {
    const dayOfTheWeek = this.getDayOfTheWeek(monthStart);
    const previousMonthEnd = monthStart.subtract(1, 'month').endOf('M');

    return dayOfTheWeek === 0
      ? []
      : this.getMonthDates(
        previousMonthEnd.subtract(dayOfTheWeek - 1, 'day'),
        previousMonthEnd
      );
  }

  private getNextMonthDates(monthEnd: Dayjs, previousDatesCount: number): DayItem[] {
    const nextMonthStart = monthEnd.add(1, 'month').startOf('M');

    return this.getMonthDates(
      nextMonthStart,
      nextMonthStart.add(this.calendarDaysCountTarget - previousDatesCount - 1, 'day')
    );
  }

  private getDayItem(date: Dayjs, isDimmed: boolean): DayItem {
    const packageInfo = this.getPackageInfo(date);

    return {
      date,
      dateString: date.toISOString(),
      displayDate: date.format('D'),
      displayDayWeek: capitalize(getRuDay(date)),
      isFeedDate: !!packageInfo,
      isDeliveryDate: packageInfo?.isDeliveryDate,
      packageState: packageInfo?.packageState,
      isDisabled: this.dateFilter(date),
      isDimmed
    };
  }

  private getDayOfTheWeek(date: Dayjs): number {
    const day = date.day();

    return day === 0 ? 6 : day - 1;
  }

  private getPackageInfo(date: Dayjs): ClientSubscriptionPackage {
    const formattedDate = date.format('DD/MM/YYYY');

    return this.packages.find((item: ClientSubscriptionPackage) => {
      return dayjs(item.date).format('DD/MM/YYYY') === formattedDate;
    });
  }

}
