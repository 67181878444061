import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ImageFileTypeEnum, ImageTypeEnum } from '@shared/enums';
import { DishImagePipe } from '@shared/pipes';

@Component({
  selector: 'app-dish-img',
  templateUrl: './dish-img.component.html',
  styleUrls: ['./dish-img.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DishImagePipe],
})
export class DishImgComponent {
  @Input() dishId: string;
  @Input() containerType: string = '';

  public readonly ImageTypeEnum = ImageTypeEnum;
  public readonly ImageFileTypeEnum = ImageFileTypeEnum;

  constructor(private dishImagePipe: DishImagePipe) {}

  public getImageSources(dishId: string, imageType: ImageTypeEnum, fileType: ImageFileTypeEnum): string {
    if (!dishId) {
      return null;
    }

    const version = '?v=1';
    const imageUrl = this.dishImagePipe.transform(dishId, imageType, fileType) + version;

    return `${imageUrl}`;
  }

  public getImageSourcesRetina(dishId: string, imageType: ImageTypeEnum, fileType: ImageFileTypeEnum): string {
    if (!dishId) {
      return null;
    }

    const version = '?v=1';
    const imageUrl = this.dishImagePipe.transform(dishId, imageType, fileType, true) + version + ' 2x';

    return imageUrl;
  }
}
