<picture *ngIf="dishId" [ngStyle]="dishId | customSize" class="img-container modification-{{ containerType | dishTransform  }}">
  <source
    fallbackSrc="/assets/images/client-menu/empty-dish.png"
    [appValidateSrcset]="getImageSources(dishId, ImageTypeEnum.newDesignMobile, ImageFileTypeEnum.webp) + ', ' + getImageSourcesRetina(dishId, ImageTypeEnum.newDesignMobile, ImageFileTypeEnum.webp)"
    type="image/webp"
  />

  <img
    class="img"
    [src]="getImageSources(dishId, ImageTypeEnum.newDesign, ImageFileTypeEnum.webp)"
    [srcset]="getImageSourcesRetina(dishId, ImageTypeEnum.newDesign, ImageFileTypeEnum.webp)"
  />
</picture>
