export enum DeliveryOptionsEnum {
  empty = '',

  sportWeekdays = 'Доставка пн-вт-чт или пн-ср-пт в любой двухчасовой интервал с 6 до 11 утра',
  sportWeekdaysAndSaturday = 'Доставка пн-вт-чт-сб или пн-ср-пт в любой двухчасовой интервал с 6 до 11 утра',
  sportAllWeek = 'Доставка пн-вт-чт-сб или пн-ср-пт-сб в любой двухчасовой интервал с 6 до 11 утра',

  fitWeekdays = 'Доставка пн-вт-чт или пн-ср-пт в любой часовой интервал с 6 до 11 утра',
  fitWeekdaysAndSaturday = 'Доставка пн-вт-чт-сб или пн-ср-пт в любой часовой интервал с 6 до 11 утра',
  fitAllWeek = 'Доставка пн-вт-чт-сб или пн-ср-пт-сб в любой часовой интервал с 6 до 11 утра',

  lifeWeekdays = 'Доставка пн-вт-чт или пн-ср-пт в любой 30-минутный интервал с 6 до 11 утра',
  lifeWeekdaysAndSaturday = 'Доставка пн-вт-чт-сб или пн-ср-пт в любой 30-минутный интервал с 6 до 11 утра',
  lifeAllWeek = 'Доставка пн-вт-чт-сб или пн-ср-пт в любой 30-минутный интервал с 6 до 11 утра',

  vegWeekdays = 'Доставка пн-вт-чт в любой часовой интервал с 6 до 11 утра',
  vegWeekdaysAndSaturday = 'Доставка пн-вт-чт-сб в любой часовой интервал с 6 до 11 утра',
  vegAllWeek = 'Доставка пн-вт-чт-сб в любой часовой интервал с 6 до 11 утра',
}
