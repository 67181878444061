export enum GendersEnum {
  male = 'Male',
  female = 'Female'
}

export enum TargetsEnum {
  loseWeight = 'loseWeight',
  keepShape = 'keepShape',
  gainWeight = 'gainWeight'
}

export enum ActivityLevelsEnum {
  low = 'low',
  middle = 'middle',
  high = 'high',
  extremelyHigh = 'extremelyHigh'
}

export const GenderShortNamesEnum = {
  [GendersEnum.male]: 'М',
  [GendersEnum.female]: 'Ж'
};

export const GenderFullNamesEnum = {
  [GendersEnum.male]: 'Мужской',
  [GendersEnum.female]: 'Женский'
};

export const TargetNamesEnum = {
  [TargetsEnum.loseWeight]: 'Похудеть',
  [TargetsEnum.keepShape]: 'Поддержать форму',
  [TargetsEnum.gainWeight]: 'Набрать массу'
};

export const ActivityLevelNamesEnum = {
  [ActivityLevelsEnum.low]: 'Низкий',
  [ActivityLevelsEnum.middle]: 'Средний',
  [ActivityLevelsEnum.high]: 'Повышенный',
  [ActivityLevelsEnum.extremelyHigh]: 'Экстремальный'
};
