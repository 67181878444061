import { Observable } from 'rxjs';

export function hasImage(path: string): Observable<boolean> {
  return new Observable((observer) => {
    const img = new Image();

    img.crossOrigin = 'anonymous';
    img.onload = () => {
      observer.next(true);
      observer.complete();
    };

    img.onerror = () => {
      observer.next(false);
      observer.complete();
    };
    img.src = path;
  });
}
