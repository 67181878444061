import { Directive, ElementRef, Input, Inject, OnInit, PLATFORM_ID, Self } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { takeUntil } from 'rxjs/operators';

import { NgOnDestroyService } from '@shared/services';
import { hasImage } from '@shared/utils';

@Directive({
  selector: '[appImageNotFound]',
  providers: [NgOnDestroyService],
})
export class ImageNotFoundDirective implements OnInit {
  @Input() public appImageNotFound = '';

  public elementClass = 'state-loaded';

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private elementRef: ElementRef,
    @Self() private ngOnDestroy$: NgOnDestroyService,
  ) { }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) { return; }

    const elem = this.elementRef.nativeElement;
    const fallbackImage = elem.getAttribute('fallbackImage');

    elem.onload = () => {
      elem.classList.add(this.elementClass);
    };

    elem.onerror = () => {
      hasImage(fallbackImage)
        .pipe(
          takeUntil(this.ngOnDestroy$),
        )
        .subscribe((hasFallbackImage) => {
          elem.src = hasFallbackImage ? fallbackImage : this.appImageNotFound;
          elem.classList.add(this.elementClass);
        });
    };
  }

}
