import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'f'
})
export class FunctionPipe implements PipeTransform {
    transform<T>(value: any, func: (value, params?: any[]) => T, args?: any[]): T {
        return args ? func(value, ...args) : func(value);
    }
}
