<app-modal-layout [showBack]="true" [title]="'Оцените блюдо'" (onGoBack)="closeModal()" (onClose)="closeModal()">
  <form [formGroup]="form" class="content" #feedbackForm>
    <div class="general-info modification-{{ dish?.containerType | dishTransform  }}">
      <div class="image-con">
        <img
          class="img"
          [appImageNotFound]="'/assets/images/client-menu/empty-dish.png'"
          [attr.src]="(dish?.id | dishImage: ImageTypeEnum.sitePreview)"
          alt="prostoeda"
        />
      </div>

      <div class="text-details">
        <div class="day-info">{{day}}, {{ date | date:'d MMMM' }}</div>
        <div class="calories">{{ dish?.grams }} г / {{ dish?.caloricity }} <span>ккал</span></div>
        <div class="dish-title">{{ dish?.title }}</div>
      </div>
    </div>

    <div class="rating">
      <div class="label">Вам понравилось блюдо?</div>
      <app-rating
        formControlName="rating"
        id="dish-feedback-rating"
        class="rating-widget"
        (changeValue)="onSave(false, $event)"
      ></app-rating>
    </div>

    <ng-container *ngIf="isRatingSet">
      <div class="feedback">
        <div class="label">Расскажите как вам блюдо</div>
        <app-select-variant class="answers" [items]="questions$ | async" formControlName="answers"></app-select-variant>
      </div>

      <div class="comment">
        <textarea placeholder="Комментарий" class="comment-textarea" formControlName="comment" tabindex="-1"></textarea>
      </div>
    </ng-container>
  </form>

  <button class="btn btn-base btn-send" *ngIf="form?.get('rating')?.value > 0" [disabled]="form.invalid" (click)="onSave()">
    Отправить
  </button>
</app-modal-layout>
