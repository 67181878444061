import { ProgramDurationsEnum } from '@shared/enums';

export function formatToDuration(count: number): ProgramDurationsEnum {
  if (!count) {
    return null;
  }

  if (count === 1) {
    return ProgramDurationsEnum.oneDay;
  }

  if (count === 2) {
    return ProgramDurationsEnum.twoDays;
  }

  if (count <= 7) {
    return ProgramDurationsEnum.oneWeek;
  }

  if (count <= 14) {
    return ProgramDurationsEnum.twoWeeks;
  }

  return ProgramDurationsEnum.fourWeeks;
}
