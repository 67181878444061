import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';

import { environment } from 'src/environments/environment';
import { ngxsConfig } from './core';
import { AuthState } from './auth';
import { ProfileState } from './profile';
import { ProgramState } from './program';
import { LoadingState } from './loading';
import { GlobalState } from './global';
import { PaymentState } from './payment';
import { ClientMenuState } from './client-menu';
import { NotificationsState } from './notifications';

@NgModule({
  imports: [
    NgxsModule.forRoot(
      [AuthState, GlobalState, ProfileState, LoadingState, ProgramState, PaymentState, ClientMenuState, NotificationsState],
      ngxsConfig,
    ),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
  ],
})
export class StoreModule {}
