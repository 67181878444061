import { ChangeDetectionStrategy, Component, inject, ViewChild } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IonModal } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';
import { Store } from '@ngxs/store';

import { environment } from 'src/environments/environment';
import { AuthService } from '@shared/services';
import { LocalStorageKeysEnum } from '@shared/enums';
import { ProfileData } from '@shared/models';
import { ProfileSelectors } from '@store/profile';

import packageData from 'package.json';

@Component({
  selector: 'app-admin-container',
  templateUrl: './admin-container.component.html',
  styleUrls: ['./admin-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminContainerComponent {
  public fullName$: Observable<string> = inject(Store).select(ProfileSelectors.fullName);
  public phone$: Observable<string> = inject(Store).select(ProfileSelectors.phone);
  public isNewClient$: Observable<boolean> = inject(Store).select(ProfileSelectors.isNewClient);
  public profile$: Observable<ProfileData> = inject(Store).select(ProfileSelectors.profile);

  public address$: Observable<string> = this.profile$.pipe(
    map(profile => {
      const address = profile?.defaultDeliverySettings?.address;

      if (!address) {
        return '-';
      }

      return [address?.customAddressString, address?.entranceApartment].filter(Boolean).join(', ');
    }),
  );

  public deliveryTimeFrom$ = this.profile$.pipe(
    map(profile => {
      const deliveryFrom = profile?.defaultDeliverySettings?.deliveryTimeFrom;

      return deliveryFrom ? deliveryFrom?.hours + ':' + deliveryFrom?.minutes : '-';
    }),
  );

  public deliveryTimeTo$ = this.profile$.pipe(
    map(profile => {
      const deliveryTimeTo = profile?.defaultDeliverySettings?.deliveryTimeTo;

      return deliveryTimeTo ? deliveryTimeTo?.hours + ':' + deliveryTimeTo?.minutes : '-';
    }),
  );

  @ViewChild(IonModal) modal: IonModal;

  public isDev = !environment.production;
  public version = packageData.version;

  constructor(private authService: AuthService) {}

  public close(): void {
    this.modal.dismiss(null, 'cancel');
  }

  public logout(): void {
    this.authService.logout();
    this.close();
  }

  public async showIntro() {
    await Preferences.remove({
      key: LocalStorageKeysEnum.introSeen,
    });

    this.logout();
  }
}
