import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateAgo',
    pure: true
})
export class DateAgoPipe implements PipeTransform {

    transform(value: any): any {
        if (value) {
            const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);

            if (seconds < 60) {
                return 'Только что';
            }

            const intervals = {
                'недели': 604800,
                'дней': 86400,
                'часов': 3600,
                'минут': 60
            };

            for (const i in intervals) {
                const intervalSeconds = intervals[i];
                const counter = Math.floor(seconds / intervalSeconds);

                if (counter > 0) {
                    const unit = i as keyof typeof intervals;

                    switch (unit) {
                        case 'недели':
                            if (seconds % intervalSeconds === 0) {
                                return counter + ' недели ago';
                            }
                            break;
                        case 'дней':
                            if (counter % 7 === 0) {
                                return (counter / 7) + ' дней назад';
                            }
                            if (counter === 1) {
                                return counter + ' день назад';
                            } else if (counter === 2 || counter === 3 || counter === 4) {
                                return counter + ' дня назад';
                            } else {
                                return counter + ' ' + i + ' назад';
                            }
                        case 'часов':

                            if (counter % 24 === 0) {
                                return (counter / 24) + ' часов назад';
                            }
                            if (counter === 1) {
                                return counter + ' час назад';
                            } else if (counter === 2 || counter === 3 || counter === 4) {
                                return counter + ' часа назад';
                            } else {
                                return counter + ' часов назад';
                            }
                        case 'минут':
                            if (counter % 60 === 0) {
                                return (counter / 60) + ' минут назад';
                            }
                            if (counter === 1) {
                                return 'Минуту назад';
                            } else {
                                return counter + ' минут назад';
                            }
                    }
                }
            }
        }
        return value;
    }
}
