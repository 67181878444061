
const weekdays = ['Вc', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
const shortMonths = ['Янв.', 'Фев.', 'Март.', 'Апр.', 'Мая', 'Июн.', 'Июля', 'Авг.', 'Сент.', 'Окт.', 'Нояб.', 'Дек.'];

export function formatDate(value: string, displayFormat: 'input' | 'calendar' = 'input'): string {
  const date = new Date(value);
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  const weekday = date.getDay();

  const inputString = `${weekdays[weekday]}, ${day} ${months[month]}`;
  const calendarString = `${shortMonths[month]} ${year}`;

  return displayFormat === 'calendar' ? calendarString : inputString;
}
