import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { take } from 'rxjs/operators';

import { EventPayload, EventProperties } from '@shared/models';
import { ProfileSelectors } from '@store/profile';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class RudderStackService {

  private initialReferrer: string;

  constructor(
    private http: HttpClient,
    private store: Store,
    private cookieService: CookieService,
  ) {}

  public identify(userId: string): void {

    const url = `${environment.url.rudderstack}/identify`;
    const requestPayload = this.generateRequestPayload(userId);

    this.http.post(url, requestPayload).subscribe();
  }

  public track(eventName: string, properties?: EventProperties): void {

    const url = `${environment.url.rudderstack}/track`;
    const anonymousId = this.getVisitorId();
    const profile = this.store.selectSnapshot(ProfileSelectors.profile);

    const userId = new URL(document.URL).searchParams.get('clientGuid') || profile?.id || null;
    const requestPayload = this.generateRequestPayload(userId, anonymousId, eventName, properties);

    this.http.post(url, requestPayload)
      .pipe(
        take(1),
      )
      .subscribe();
  }

  private generateRequestPayload(
    userId: string,
    anonymousId?: string,
    eventName?: string,
    properties?: EventProperties
  ): EventPayload {
    return {
      anonymousId,
      userId,
      eventName,
      properties,
      context: {
        userAgent: window.navigator.userAgent,
        page: {
          url: window.location.href,
          path: window.location.pathname,
          referrer: document.referrer,
          initial_referrer: this.initialReferrer
        }
      },
      integrations: {
        All: true
      }
    };
  }

  private getVisitorId(): string {
    return this.cookieService.get('_jft_uid') || 'local_development';
  }
}
