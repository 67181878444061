import dayjs, { Dayjs } from 'dayjs';

import { AvailableMovingDays, DayItem } from '@shared/models';
import { monthGenitiveDescription } from '@shared/enums';

export function getChosenDates(dayItem: DayItem, movingDaysData: AvailableMovingDays): Dayjs[] {
  if (!movingDaysData?.movingDays) { return []; }

  const movingDaysCount = movingDaysData.movingDays.length;
  const chosenDates = [];

  for (let i = 0; i < movingDaysCount; i++) {
    const newDay = dayItem.date.add(i, 'day');
    chosenDates.push(newDay);
  }

  return chosenDates;
}

function formatDate(d: string | Dayjs): string {
  const days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
  const dayOfWeek = days[dayjs(d).day()];

  return `${dayOfWeek}, ${dayjs(d).format('D')} ${monthGenitiveDescription[dayjs(d).format('M')]}`;
}

export function getHighlightedCalendarDays(dayItem: DayItem, movingDaysData: AvailableMovingDays): string[] {
  return getChosenDates(dayItem, movingDaysData).map(d => d.toISOString());
}

export function getChosenCalendarDaysText(dayItem: DayItem, movingDaysData: AvailableMovingDays, joinFormat = ' <i>+</i><br>', isLowercase = true): string {
  const chosenDates = getChosenDates(dayItem, movingDaysData);
  let formattedDates = chosenDates.map(d => isLowercase ? formatDate(d).toLowerCase() : formatDate(d));

  return formattedDates.join(joinFormat);
}

export function getDaysToMoveText(movingDaysData: AvailableMovingDays): string {
  if (!movingDaysData?.movingDays) {
    return null;
  }

  const { movingDays } = movingDaysData;
  const formattedDays = movingDays.map(d => formatDate(d));

  return formattedDays.join(' <i>+</i><br>');
}
