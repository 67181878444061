import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { DadataResp, DaDataSuggestion } from '@shared/models';

@Injectable({
  providedIn: 'root'
})
export class DadataService {

  private dadaApiUrl = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';
  private requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Token ${environment.daDataToken}`
    }
  };

  constructor(
    private http: HttpClient
  ) { }

  public getSuggestions(query: string): Observable<DadataResp> {
    const reqBody = {
      query,
      locations: [
        { kladr_id: '7700000000000' },
        { kladr_id: '50' }
      ],
      restrict_value: true,
      from_bound: { value: 'street' },
      to_bound: { value: 'house' }
    };

    return this.http.post<DadataResp>(this.dadaApiUrl, JSON.stringify(reqBody), this.requestOptions);
  }

  public getDetailSuggestion(query: string): Observable<DaDataSuggestion> {
    const reqBody = {
      query,
      count: 1
    };

    return this.http.post<DadataResp>(this.dadaApiUrl, JSON.stringify(reqBody), this.requestOptions)
      .pipe(
        map((response: DadataResp) => response.suggestions[0])
      );
  }

  public getAddressCoordinates(suggestion: DaDataSuggestion): Observable<[number, number]> {
    const coords = this.getCoordinates(suggestion);

    if (coords[0] && coords[1]) { return of(coords); }

    return this.getDetailSuggestion(suggestion.unrestricted_value)
      .pipe(
        map((data: DaDataSuggestion) => this.getCoordinates(data))
      );
  }

  private getCoordinates(suggestion: DaDataSuggestion): [number, number] {
    return [
      parseFloat(suggestion?.data?.geo_lat),
      parseFloat(suggestion?.data?.geo_lon)
    ];
  }
}
