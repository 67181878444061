import { Selector } from '@ngxs/store';

import { GendersEnum } from '@shared/enums';
import { ProfileData } from '@shared/models';
import { ProfileStateModel } from './profile.model';

export class ProfileSelectors {
  @Selector([state => state.profile])
  static state(state: ProfileStateModel): ProfileStateModel {
    return state;
  }

  @Selector([ProfileSelectors.state])
  static profile(state: ProfileStateModel): ProfileData {
    return state.profile;
  }

  @Selector([ProfileSelectors.state])
  static clientId(state: ProfileStateModel): string {
    return state.profile?.id;
  }

  @Selector([ProfileSelectors.profile])
  static fullName(profile: ProfileData): string {
    return [profile?.firstName, profile?.lastName].filter(Boolean).join(' ');
  }

  @Selector([ProfileSelectors.profile])
  static phone(profile: ProfileData): string {
    return profile?.phone;
  }

  @Selector([ProfileSelectors.state])
  static gender(state: ProfileStateModel): GendersEnum {
    return state.gender;
  }

  @Selector([ProfileSelectors.profile])
  static isNewClient(profile: ProfileData): boolean {
    return profile ? profile.isNewClient : true;
  }

  @Selector([ProfileSelectors.state])
  static isProfileExist(state: ProfileStateModel): boolean {
    return Boolean(state.profile);
  }
}
