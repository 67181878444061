import { Selector } from '@ngxs/store';

import {
  AvailableDiscount,
  AvailableMovingDays,
  CurrentProgram,
  DeliveryIntervalResp,
  SubscriptionInfoForThanksPage,
  SubscriptionPriceResponse,
} from '@shared/models';
import { ProgramStateModel } from './program.model';

export class ProgramSelectors {
  @Selector([state => state.program])
  static state(state: ProgramStateModel): ProgramStateModel {
    return state;
  }

  @Selector([ProgramSelectors.state])
  static program(state: ProgramStateModel): CurrentProgram {
    return state?.program;
  }

  @Selector([ProgramSelectors.state])
  static isFamily(state: ProgramStateModel): boolean {
    return state.isFamily;
  }

  @Selector([ProgramSelectors.state])
  static isHideFamily(state: ProgramStateModel): boolean {
    return state.isHideFamily;
  }

  @Selector([ProgramSelectors.state])
  static promocode(state: ProgramStateModel): string {
    return state.promocodeFromInput || state.promocodeFromUrl || '';
  }

  @Selector([ProgramSelectors.state])
  static availableDiscounts(state: ProgramStateModel): AvailableDiscount[] {
    return state?.availableDiscounts;
  }

  @Selector([ProgramSelectors.state])
  static startDeliveryDay(state: ProgramStateModel): string {
    return state?.startDeliveryDay;
  }

  @Selector([ProgramSelectors.state])
  static availableDates(state: ProgramStateModel): string[] {
    return state?.availableDates;
  }

  @Selector([ProgramSelectors.state])
  static promocodeErrorMessage(state: ProgramStateModel): string {
    return state?.promocodeErrorMessage;
  }

  @Selector([ProgramSelectors.state])
  static deliveryIntervalInfo(state: ProgramStateModel): DeliveryIntervalResp {
    return state?.deliveryIntervalInfo;
  }

  @Selector([ProgramSelectors.state])
  static calendarAvailableMovingDays(state: ProgramStateModel): AvailableMovingDays {
    return state.calendarAvailableMovingDays;
  }

  @Selector([ProgramSelectors.state])
  static isLoadingMovingDays(state: ProgramStateModel): boolean {
    return state.isLoadingMovingDays;
  }

  @Selector([ProgramSelectors.program])
  static durationInDays(program: CurrentProgram): number {
    return program.durationInDays;
  }

  @Selector([ProgramSelectors.state])
  static priceData(state: ProgramStateModel): SubscriptionPriceResponse {
    return state.priceData;
  }

  @Selector([ProgramSelectors.state])
  static subscriptionInfo(state: ProgramStateModel): SubscriptionInfoForThanksPage {
    return state.subscriptionInfo;
  }
}
