<ng-container *ngIf="!isLoading; else loading">
  <svg [attr.class]="'icon'">
    <use [attr.xlink:href]="use"></use>
  </svg>
</ng-container>

<ng-template #loading>
  <ion-spinner name="dots"></ion-spinner>
</ng-template>

