import { ClientMenuDish } from '@modules/client-menu/models';
import { Selector } from '@ngxs/store';

import { ClientSubscription, ClientSubscriptionDetails, ClientSubscriptionPackage, Notification } from '@shared/models';
import { ClientMenuStateModel, Ingredient, ReplacementDiffDishes } from './client-menu.model';

export class ClientMenuSelectors {
  @Selector([state => state.clientMenu])
  static state(state: ClientMenuStateModel): ClientMenuStateModel {
    return state;
  }

  @Selector([ClientMenuSelectors.state])
  static areSubscriptionsLoaded(state: ClientMenuStateModel): boolean {
    return state?.areSubscriptionsLoaded;
  }

  @Selector([ClientMenuSelectors.state])
  static subscriptionsList(state: ClientMenuStateModel): ClientSubscription[] {
    return Object.values(state.subscriptionList);
  }

  @Selector([ClientMenuSelectors.state])
  static subscriptionDetails(state: ClientMenuStateModel): ClientSubscriptionDetails {
    return state.activeSubscriptionDetails;
  }

  @Selector([ClientMenuSelectors.state])
  static subscriptionsCount(state: ClientMenuStateModel): number {
    return state.subscriptionList?.length || 0;
  }

  @Selector([ClientMenuSelectors.state])
  static activeSubscriptionId({ activeSubscriptionDetails }: ClientMenuStateModel): string {
    return activeSubscriptionDetails?.subscriptionId || null;
  }

  @Selector([ClientMenuSelectors.state])
  static excludedIngredients(state: ClientMenuStateModel): Ingredient[] {
    if (!state?.ingredientsList) {
      return [];
    }

    return state.ingredientsList.filter(ingredient => ingredient.isSelected);
  }

  @Selector([ClientMenuSelectors.state])
  static excludedIngredientsNames(state: ClientMenuStateModel): string[] {
    if (!state?.ingredientsList) {
      return [];
    }

    return state.ingredientsList.filter(ingredient => ingredient.isSelected).map(ingredient => ingredient.name);
  }

  @Selector([ClientMenuSelectors.state])
  static isIngredientsExclusionEnabled(state: ClientMenuStateModel): boolean {
    return state?.isIngredientsExclusionEnabled;
  }

  @Selector([ClientMenuSelectors.state])
  static isDialogIngredientsExclusionEnabled(state: ClientMenuStateModel): boolean {
    return state?.isDialogIngredientsExclusionEnabled;
  }

  @Selector([ClientMenuSelectors.state])
  static activePackage({ activePackage }: ClientMenuStateModel): ClientSubscriptionPackage {
    return activePackage;
  }

  @Selector([ClientMenuSelectors.state])
  static activePackageId({ activePackage }: ClientMenuStateModel): string {
    return activePackage?.packageId;
  }

  @Selector([ClientMenuSelectors.state])
  static unfinishedPaymentId({ activeSubscriptionDetails }: ClientMenuStateModel): string {
    if (!activeSubscriptionDetails) {
      return null;
    }

    return activeSubscriptionDetails?.unfinishedPaymentId;
  }

  @Selector([ClientMenuSelectors.state])
  static isMenuLoading(state: ClientMenuStateModel): boolean {
    return state.isMenuLoading;
  }

  @Selector([ClientMenuSelectors.state])
  static isCalendarLoading(state: ClientMenuStateModel): boolean {
    return state.isCalendarLoading;
  }

  @Selector([ClientMenuSelectors.state])
  static isSubscriptionListLoading(state: ClientMenuStateModel): boolean {
    return state.isSubscriptionListLoading;
  }

  @Selector([ClientMenuSelectors.state])
  static isSubscriptionDetailsLoading(state: ClientMenuStateModel): boolean {
    return state.isSubscriptionDetailsLoading;
  }

  @Selector([ClientMenuSelectors.state])
  static didPageFail(state: ClientMenuStateModel): boolean {
    return state.didPageFail;
  }

  @Selector([ClientMenuSelectors.state])
  static replacementsInSubscription({ activeSubscriptionDetails, changedDish }: ClientMenuStateModel): ReplacementDiffDishes[] {
    return changedDish?.[activeSubscriptionDetails?.subscriptionId] || [];
  }

  @Selector([ClientMenuSelectors.state])
  static replacementsDishes({ replacementDishes }: ClientMenuStateModel): ClientMenuDish[] {
    return replacementDishes;
  }

  @Selector([ClientMenuSelectors.state])
  static sameTypeDishes({ replacementDishes }: ClientMenuStateModel): ClientMenuDish[] {
    return replacementDishes.filter(dish => dish.isSameMenuType);
  }

  @Selector([ClientMenuSelectors.state])
  static otherDishes({ replacementDishes }: ClientMenuStateModel): ClientMenuDish[] {
    return replacementDishes.filter(dish => !dish.isSameMenuType);
  }

  @Selector([ClientMenuSelectors.state])
  static customizationsCount({ activeSubscriptionDetails, changedDish }: ClientMenuStateModel): number {
    const changesInSubscription = changedDish[activeSubscriptionDetails?.subscriptionId];
    return changesInSubscription?.length || 0;
  }

  @Selector([ClientMenuSelectors.state])
  static additionsCount({ activeSubscriptionDetails, changedDish }: ClientMenuStateModel): number {
    const changesInSubscription = changedDish[activeSubscriptionDetails.subscriptionId];

    return changesInSubscription?.length || 0;
  }

  @Selector([ClientMenuSelectors.state])
  static customizationPrice({ activeSubscriptionDetails, changedDish }: ClientMenuStateModel): number {
    if (!activeSubscriptionDetails) {
      return 0;
    }

    const changesInSubscription = changedDish[activeSubscriptionDetails?.subscriptionId];

    if (!changesInSubscription) {
      return 0;
    }

    const price = changesInSubscription.reduce((prev, cur) => {
      const additionalPrice = cur?.newDish?.additionalPrice || 0;
      const priceIncrease = cur?.newDish?.priceIncrease || 0;
      return prev + additionalPrice + priceIncrease;
    }, 0);

    return price;
  }

  @Selector([ClientMenuSelectors.state])
  static getNotifications({ notifications }: ClientMenuStateModel): Notification[] {
    return notifications;
  }
}
