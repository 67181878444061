export function getMappedValue<T>(value: T[], fieldName: string): Record<string, T> {
  return value.reduce((res, item: T) => {
    const key = item[fieldName];

    if (!key) {
      return res;
    }

    res[key] = item;
    return res;
  }, {});
}
