<div class="info" *ngIf="packageParams?.amount > 0">
  <div class="info-left">
    {{ packageParams.amount }} {{ packageParams.amount | pluralize: 'dishes' }}
  </div>
  <div class="info-right">
    <span class="info-item">
      <b>К</b> {{ packageParams.caloricity | customNumber: 'pfc' }}
    </span>
      <span class="info-item">
        <b>Б</b> {{ packageParams.proteins | customNumber: 'pfc' }}
      </span>
    <span class="info-item">
        <b>Ж</b> {{ packageParams.fats | customNumber: 'pfc' }}
      </span>
    <span class="info-item">
        <b>У</b> {{ packageParams.carbs | customNumber: 'pfc' }}
      </span>
  </div>
</div>
