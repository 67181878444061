import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { Menu } from '@shared/models';
import { DaysOfWeekEnum, MenuTargetsEnum, ProgramTypesEnum, CacheTypeEnum } from '@shared/enums';
import { CacheService } from '../common/cache.service';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(private http: HttpClient, private cacheService: CacheService) {}

  getMenu(calories: number, dayOfWeek: DaysOfWeekEnum, target: MenuTargetsEnum): Observable<Menu[]> {
    const url = `${environment.url.menu}/v2/Sport/example?dayOfWeek=${dayOfWeek}&calories=${calories}&mode=ExperimentalWeek`;
    const cacheKey = url + `&target=${target}`;

    if (this.cacheService.has(cacheKey, CacheTypeEnum.menu)) {
      return of(this.cacheService.get<Menu[]>(cacheKey, CacheTypeEnum.menu));
    }

    return this.http.get<Menu[]>(url).pipe(
      tap((menu: Menu[]) => {
        this.cacheService.set(cacheKey, menu, CacheTypeEnum.menu);
      }),
    );
  }
}
