import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { PaymentDetails, Card, PaymentSbpInfoResponse } from '@shared/models';
import { PaymentSbpStatusEnum } from '@shared/enums';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PaymentApiService {
  constructor(private http: HttpClient) {}

  payBySavedCard(paymentId: string, cardId: string): Observable<any> {
    const url = environment.url.payToken;
    const model = {
      cardGuid: cardId,
      paymentId,
    };

    return this.http.post<Card[]>(url, model);
  }

  getPaymentDetails(paymentId: string): Observable<PaymentDetails> {
    const url = `${environment.url.paymentInfo}/${paymentId}`;

    return this.http.get<PaymentDetails>(url);
  }

  getCardsByPaymentId(paymentId: string): Observable<Card[]> {
    const url = `${environment.url.cards}?paymentGuid=${paymentId}`;

    return this.http.get<Card[]>(url);
  }

  payBySbp(paymentId: string, paymentMode: 'Image' | 'Link' = 'Link'): Observable<PaymentSbpInfoResponse> {
    return this.http.post<PaymentSbpInfoResponse>(environment.url.paymentSbp, { paymentId, paymentMode });
  }

  getPaymentSbpStatus(paymentId: string): Observable<PaymentSbpStatusEnum> {
    const url = `${environment.url.paymentSbp}/status/${paymentId}`;
    return this.http.get<PaymentSbpStatusEnum>(url);
  }
}
