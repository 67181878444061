<div class="wrapper modification-{{ dish?.containerType | dishTransform  }}">
  <div class="inner">
    <div class="back-image state-{{ programType | lowercase}}"></div>
    <picture class="img-container" (click)="open(dish)">
      <div class="badge confirmation-text" *ngIf="dish?.shouldBeConfirmed || (dish?.isReplaced && !package.hasConfirmedCustomization)">
        Нужно подтвердить
      </div>
      <div class="badge replaced-text" *ngIf="dish?.isReplaced && package.hasConfirmedCustomization">Заменено</div>
      <div class="badge replaced-text" *ngIf="dish?.isAdded && package.hasConfirmedCustomization">Добавлено</div>
      <img
        class="img"
        [appImageNotFound]="'/assets/images/client-menu/empty-dish.png'"
        [src]="dish?.id | dishImage : ImageTypeEnum.sitePreview : ImageFileTypeEnum.png"
      />
    </picture>
    <div class="content">
      <div class="menu-type" *ngIf="dish?.mealType">{{ ruDishTypeEnum[dish?.mealType] }}</div>
      <div class="text-info">
        <div class="info-left">{{ dish.grams | customNumber: 'weight'}} г/ {{ dish.caloricity | customNumber: 'calories' }} ккал</div>
        <div class="info-right">
          <span class="info-detail" (click)="open(dish)">Состав</span>
        </div>
      </div>
      <div class="text-title">{{ dish.title }}</div>

      <app-select-dish-modal
        [dish]="dish"
        [package]="package"
        (undoSelect)="deselect(dish)"
        (rateDish)="setDishRating(dish, package)"
      ></app-select-dish-modal>

      <ng-container *ngIf="dish.shouldBeConfirmed">
        <div class="content-footer">
          <div class="price" *ngIf="dish?.priceIncrease || dish?.additionalPrice">
            +{{ dish?.priceIncrease || dish?.additionalPrice }} <span class="ruble">₽</span>
          </div>
        </div>
      </ng-container>

      <app-rating
        *ngIf="dish.dishFeedback?.rating > 0"
        class="rating-widget"
        [isSilent]="true"
        size="small"
        [value]="dish.dishFeedback.rating"
        (openDishRatingModal)="setDishRating(dish, package)"
      ></app-rating>
    </div>
  </div>
</div>
