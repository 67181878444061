import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customNumber'
})
export class CustomNumberPipe implements PipeTransform {
  transform(value: number, type: 'price' | 'weight' | 'calories' | 'pfc'): string {
    if (value && !isNaN(value) && type) {
      switch (type) {
        case 'price':
          // TODO: поменять способ форматирования, т.к. на сервере (в ноде) нету локалей
          // сейчас сервер рендерит так: '5,000', а клиент так: '5 000'
          return Math.ceil(value).toLocaleString('ru');

        case 'weight':
          return Math.round(value).toLocaleString('ru');

        case 'calories':
          return Math.round(value).toLocaleString('ru');

        case 'pfc':
          return (Math.ceil(value * 10) / 10).toLocaleString('ru');

        default:
          return '0';
      }
    } else {
      return '0';
    }
  }
}
