import { ChangeDetectionStrategy, Output, EventEmitter, Input, Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import Swiper from 'swiper';

import { trackByProperty } from '@shared/utils';
import { ClientSubscription } from '@shared/models';

@Component({
  selector: 'app-subscription-selector',
  templateUrl: './subscription-selector.component.html',
  styleUrls: ['./subscription-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionSelectorComponent implements AfterViewInit {
  @ViewChild('swiper')
  swiperRef: ElementRef | undefined;

  get swiper(): Swiper {
    return this.swiperRef?.nativeElement.swiper;
  }

  @Input() set data(value: ClientSubscription[]) {
    if (value && value?.length > 0) {
      this.subscriptions = value;

      this.updateSlide();
    }
  }
  @Input() isSmall = false;
  @Input() isLoading = false;
  @Input() subscriptionId = null;
  @Input() shouldNavigateToActiveSubscription: boolean = false;
  @Output() changeSubscriptionId = new EventEmitter<string>();

  trackByProperty = trackByProperty;
  subscriptions: ClientSubscription[];

  ngAfterViewInit(): void {
    if (this.shouldNavigateToActiveSubscription) {
      const nextProgramIndex = this.subscriptions.findIndex(item => this.subscriptionId === item.subscriptionId);
      if (nextProgramIndex !== undefined) {
        setTimeout(() => {
          this.swiper.slideTo(nextProgramIndex);
        }, 0);
      }
    }
  }

  updateSlide() {
    if (!this.subscriptions || !this.swiper || !this.subscriptionId) {
      return;
    }

    const activeIndex = this.swiper.activeIndex;
    const nextProgramIndex = this.subscriptions.findIndex(item => this.subscriptionId === item.subscriptionId);

    if (activeIndex === nextProgramIndex) {
      return;
    }

    if (nextProgramIndex !== undefined || !(nextProgramIndex === 0 && activeIndex === 0)) {
      setTimeout(() => {
        this.swiper.slideTo(nextProgramIndex);
      }, 0);
    }
  }

  onChangeSlide(): void {
    const activeIndex = this.swiper.activeIndex;
    const id = this.subscriptions?.[activeIndex]?.subscriptionId;

    if (id === this.subscriptionId) {
      return;
    }

    this.changeSubscriptionId.emit(id);
  }
}
