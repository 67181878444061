import { DeliveryIntervalResp } from '@shared/models';

export function getDeliveryTimeByInterval(deliveryInterval: DeliveryIntervalResp): string[] {
  const intervalInHours: number = deliveryInterval.stepInMinutes / 60;
  const timeByNumbers: number[] = [];
  let timeByStrings: string[] = [];

  timeByNumbers.push(deliveryInterval.startHour);
  timeByNumbers.push(deliveryInterval.endHour);

  do {
    timeByNumbers.splice(timeByNumbers.length - 1, 0, timeByNumbers[timeByNumbers.length - 2] + intervalInHours);
  } while (timeByNumbers[timeByNumbers.length - 2] + intervalInHours < timeByNumbers[timeByNumbers.length - 1]);

  timeByStrings = timeByNumbers.map((item: number) => {
    return `${Math.floor(item)}:${String(item * 60 - Math.floor(item) * 60).padStart(2, '0')}`;
  });

  return timeByStrings;
}
