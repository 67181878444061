import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  hasConnectionSubject = new BehaviorSubject<boolean>(true);

  get hasConnection$(): Observable<boolean> {
    return this.hasConnectionSubject.asObservable();
  }

  init(): void {
    window.addEventListener('online',  () => {
      this.hasConnectionSubject.next(true);
    });
    window.addEventListener('offline', () => {
      this.hasConnectionSubject.next(false);
    });
  }

}
