<ion-header mode="ios" class="header">
  <ion-toolbar>
    <div class="container header-container">
      <app-icon class="header-icon" icon="logo-company"></app-icon>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <router-outlet></router-outlet>
</ion-content>
