/* eslint-disable max-classes-per-file */
import { GendersEnum } from '@shared/enums';
import { ProfileData } from '@shared/models';

enum ActionTypes {
  LOAD_PROFILE = '[Profile] Load profile',
  SET_PROFILE = '[Profile] Set profile',
  SET_GENDER = '[Profile] Set gender',
}

export class LoadProfile {
  static readonly type = ActionTypes.LOAD_PROFILE;
}

export class SetProfile {
  static readonly type = ActionTypes.SET_PROFILE;
  constructor(public profile: ProfileData) { }
}

export class SetGender {
  static readonly type = ActionTypes.SET_GENDER;
  constructor(public gender: GendersEnum) { }
}
