import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@shared/shared.module';
import { containers } from './containers';
import { LayoutRoutingModule } from './layout-routing.module';

@NgModule({
  declarations: [
    ...containers
  ],
  imports: [
    CommonModule,
    SharedModule,
    LayoutRoutingModule,
  ]
})
export class LayoutModule {
}
