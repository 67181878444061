import { Injectable } from '@angular/core';

import { RudderStackService } from '@shared/services';
import { ClientMenuEventsEnum } from '../enums';

@Injectable({
  providedIn: 'root',
})
export class ClientMenuEventsService {
  constructor(private rudderstack: RudderStackService) {}

  public trackChooseSubscriptionClick(): void {
    this.rudderstack.track(ClientMenuEventsEnum.chooseSubscriptionClick);
  }

  public trackConfirmationPopupAction(res: boolean): void {
    const action = res ? ClientMenuEventsEnum.confirmationPopupConfirm : ClientMenuEventsEnum.confirmationPopupReturn;
    this.rudderstack.track(action);
  }

  public trackPaymentButtonClick(count: number, price: number, unfinishedPaymentId?: string, additionsCount?: number): void {
    const props = {
      count: count || null, // a property shouldn't be sent when equals zero
      additionsCount: additionsCount || null,
      price: price || null,
      isUnfinishedPayment: !!unfinishedPaymentId,
    };

    this.rudderstack.track(ClientMenuEventsEnum.paymentBtnClick, props);
  }

  public trackResetButtonClick(): void {
    this.rudderstack.track(ClientMenuEventsEnum.resetBtnClick);
    this.rudderstack.track(ClientMenuEventsEnum.confirmationPopupReturn);
  }
}
