import { Selector } from '@ngxs/store';

import { GlobalStateModel } from './global.model';

export class GlobalSelectors {
  @Selector([state => state.global])
  static state(state: GlobalStateModel): GlobalStateModel {
    return state;
  }

  @Selector([GlobalSelectors.state])
  static programMinimumPrices(state: GlobalStateModel): { [key: string]: number } {
    return state?.programMinimumPrices || {};
  }
}
