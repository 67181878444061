import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { LocalStorageKeysEnum, RuDishTypesEnum } from '@shared/enums';
import { Preferences } from '@capacitor/preferences';

@Component({
  selector: 'app-choose-dish-portion',
  templateUrl: './choose-dish-portion.component.html',
  styleUrls: ['./choose-dish-portion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChooseDishPortionComponent implements OnInit {
  @Input() dish: any;
  @Input() isFromAdditionModal: boolean;
  public readonly ruDishTypeEnum = RuDishTypesEnum;
  public mealType: string;
  public selectedDishIdOnLoad: number;

  constructor(private cdr: ChangeDetectorRef, private modalCtrl: ModalController) {}

  async ngOnInit(): Promise<void> {
    const mealType = await Preferences.get({ key: LocalStorageKeysEnum.replacableDishMealType });
    this.mealType = JSON.parse(mealType.value);
    this.filterIdOfDishWithHighestGramms();
    this.cdr.markForCheck();
  }

  public closeModal() {
    this.modalCtrl.dismiss();
  }

  public async selectDishPortion() {
    const selectedDishId = await Preferences.get({ key: LocalStorageKeysEnum.selectedDishForPortion });
    const dishesToReplace = await Preferences.get({ key: LocalStorageKeysEnum.replacementDishes });
    const dish = JSON.parse(dishesToReplace.value).filter(d => d.id === JSON.parse(selectedDishId.value))[0];
    this.modalCtrl.dismiss(dish);
  }

  public async storeSelectedPortion(id: number) {
    this.selectedDishIdOnLoad = id;

    await Preferences.set({
      key: LocalStorageKeysEnum.selectedDishForPortion,
      value: JSON.stringify(id),
    });

    this.cdr.markForCheck();
  }

  public filterIdOfDishWithHighestGramms(): void {
    const result = this.dish.grams.reduce(
      (acc, curr) => {
        const [key, value] = Object.entries(curr)[0];
        if (value !== undefined && value !== null && value !== 0 && value > acc.value) {
          return { key, value };
        }
        return acc;
      },
      { key: null, value: 0 },
    );
    this.storeSelectedPortion(result.key);
    this.selectedDishIdOnLoad = result.key;
  }
}
