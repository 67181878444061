
export enum AnalyticsEventEnum {
  login = 'af_login',
  firstLogin = 'af_first_login',
  profileFilled = 'profile_filled',
  orderCreated = 'order_created',
  firstOrderCreated = 'af_first_order_created',
  purchase = 'af_purchase',
  firstPurchase = 'af_first_purchase',
}
