import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import availableIcons from './configs/icons.json';
import versionInfo from './configs/version.json';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent {
  use: string;

  location = '/assets/icons/sprite/' + versionInfo.filename;

  @Input()
  set icon(icon: string) {
    if (!availableIcons[icon]) {
      this.use = `${this.location}#not-found.svg`;
      console.log(`'${icon}' icon is not found`);
    } else {
      this.use = icon ? `${this.location}#${icon}` : '';
    }
  }

  @Input() isLoading = false;
}
