
export enum CommonEvents {
  showIntro = 'SHOW_INTRO_SCREEN',
  showLoginPhone = 'SHOW_PHONE_SCREEN',
  showLoginCode = 'SHOW_ENTER_CODE_SCREEN',
  showSubscriptionsList = 'SHOW_SUBSCRIPTIONS_SCREEN',
  showSelectSubscription = 'SHOW_CREATE_SUBSCRIPTIONS_SCREEN',
  showSelectSubscriptionParams = 'SHOW_SElECT_SUBSCRIPTIONS_PARAMS',
  showEnterProfileInfo = 'SHOW_ENTER_PROFILE_DATA_SCREEN',
}
