import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatIngredients'
})
export class IngredientsPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) { return ''; }

    return value.split(',').join(', ');
  }
}
