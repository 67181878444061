import { ChangeDetectionStrategy, EventEmitter, Output, Input, Component } from '@angular/core';

import { NgOnDestroyService } from '@shared/services';
import { DayItem } from '@shared/models';
import { ProgramCaloriesEnum, ProgramTypesEnum } from '@shared/enums';

@Component({
  selector: 'app-calendar-item',
  templateUrl: './calendar-item.component.html',
  styleUrls: ['./calendar-item.component.scss'],
  providers: [NgOnDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarItemComponent {
  @Input() data: DayItem;
  @Input() dates: DayItem[];
  @Input() isCustomized = false;
  @Input() size: ProgramCaloriesEnum;
  @Input() activePackageId: string;

  @Output() select = new EventEmitter<DayItem>();

  onSelect(): void {
    if (!this.data.packageId) {
      return;
    }

    this.select.emit(this.data);
  }

  public defineFirstDate(date: DayItem) {
    return this.dates.indexOf(date) === 0;
  }
}
