import {
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  Self,
  ViewChild,
  Input,
  Inject,
  inject,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, delay, filter, finalize, skip, takeUntil, tap } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { DOCUMENT } from '@angular/common';
import { Preferences } from '@capacitor/preferences';

import { trackByFn } from '@modules/client-menu/containers/client-menu-page/client-menu-page.helpers';
import { getIngredientFilteredDishes, groupDishesWithSameTitles } from '@modules/client-menu/helpers';
import { ClientMenuDish } from '@modules/client-menu/models';
import { ClientMenuApiService } from '@modules/client-menu/services';
import { DishTypesNumericEnum, LocalStorageKeysEnum, RuDishTypesEnum } from '@shared/enums';
import { NgOnDestroyService } from '@shared/services';
import { ClientMenuSelectors, Ingredient } from '@store/client-menu';
import { sortByMealType } from '@modules/client-menu/utils/sort-by-meal-type.util';
import { ClientSubscriptionPackage } from '@shared/models';

interface DishCategories {
  [key: string]: ClientMenuDish[];
}

@Component({
  selector: 'app-dish-addition-dialog',
  templateUrl: './dish-addition-dialog.component.html',
  styleUrls: ['./dish-addition-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgOnDestroyService],
})
export class DishAdditionDialogComponent implements OnInit {
  @Input() package: ClientSubscriptionPackage;
  @Input() title: string;
  @Input() isShowSkipButton = false;

  public excludedIngredients$: Observable<Ingredient[]> = inject(Store).select(ClientMenuSelectors.excludedIngredients);
  private isDialogIngredientsExclusionEnabled$: Observable<boolean> = inject(Store).select(
    ClientMenuSelectors.isDialogIngredientsExclusionEnabled,
  );
  private isIngredientsExclusionEnabled: boolean = inject(Store).selectSnapshot(ClientMenuSelectors.isDialogIngredientsExclusionEnabled);
  private excludedIngredientsNames: string[] = inject(Store).selectSnapshot(ClientMenuSelectors.excludedIngredientsNames);

  @ViewChild('dishesListRef') private dishesListRef: ElementRef;

  public readonly RuDishTypesEnum = RuDishTypesEnum;
  public readonly trackByFn = trackByFn;
  public readonly sortByMealTypeFn = sortByMealType;

  public dishesList: ClientMenuDish[] = [];
  public dishesCategories: DishCategories;
  public scrollableContainer: HTMLElement;
  public isWinOS = false;
  public isLoading$ = new BehaviorSubject<boolean>(true);
  public areIngredientFiltering$ = new BehaviorSubject<boolean>(false);
  public activeMealTypeTab: string = 'Brunch';
  public filterMethod: string = 'filterByCcal';
  public isSortingDropdownShown$ = new BehaviorSubject<boolean>(false);

  public get tooltipContainer(): HTMLElement {
    return document.querySelector('.dish-addition-dialog');
  }

  constructor(
    @Self() private ngOnDestroy$: NgOnDestroyService,
    @Inject(DOCUMENT) private dom: Document,
    private clientMenuApiService: ClientMenuApiService,
    private cdr: ChangeDetectorRef,
    private modalCtrl: ModalController,
  ) {}

  ngOnInit(): void {
    this.fetchDishesList(this.package.packageId);
    this.initIngredientsExclusionUpdates();
  }

  public async select(dish: ClientMenuDish): Promise<void> {
    if (this.isArray(dish.id)) {
      const selectedDishId = await Preferences.get({ key: LocalStorageKeysEnum.selectedDishForPortion });
      const dishesToReplace = await Preferences.get({ key: LocalStorageKeysEnum.replacementDishes });
      const dish = JSON.parse(dishesToReplace.value).filter(d => d.id === JSON.parse(selectedDishId.value))[0];
      this.modalCtrl.dismiss(dish);
    }
    this.modalCtrl.dismiss(dish);
  }

  public closeDialog(): void {
    this.modalCtrl.dismiss();
  }

  private fetchDishesList(packageId: string): void {
    if (!packageId) {
      return;
    }

    this.isLoading$.next(true);
    this.clientMenuApiService
      .getAdditionalDishes(packageId)
      .pipe(
        catchError(() => of([])),
        finalize(() => {
          this.isLoading$.next(false);
        }),
        filter(dishesList => !!dishesList),
        takeUntil(this.ngOnDestroy$),
      )
      .subscribe(async dishesList => {
        const mergedGroupedArray = groupDishesWithSameTitles(dishesList);
        this.dishesList = mergedGroupedArray;

        await Preferences.set({
          key: LocalStorageKeysEnum.replacementDishes,
          value: JSON.stringify(dishesList),
        });

        const filteredDishes = getIngredientFilteredDishes(
          mergedGroupedArray,
          this.isIngredientsExclusionEnabled,
          this.excludedIngredientsNames,
        );

        this.dishesCategories = this.filterDishesCategories(filteredDishes);

        this.cdr.markForCheck();

        setTimeout(() => {
          this.scrollableContainer = this.dishesListRef?.nativeElement;
        });
      });
  }

  private initIngredientsExclusionUpdates(): void {
    this.isDialogIngredientsExclusionEnabled$
      .pipe(
        skip(1),
        tap(() => {
          this.areIngredientFiltering$.next(true);
        }),
        delay(250),
        takeUntil(this.ngOnDestroy$),
      )
      .subscribe(isExclusionEnabled => {
        const filteredDishes = getIngredientFilteredDishes(
          groupDishesWithSameTitles(this.dishesList),
          isExclusionEnabled,
          this.excludedIngredientsNames,
        );
        this.filterByCcal();
        this.dishesCategories = this.filterDishesCategories(filteredDishes);

        this.areIngredientFiltering$.next(false);
      });
  }

  public showSortDropdown(): void {
    if (this.isSortingDropdownShown$.getValue()) {
      return;
    }

    this.isSortingDropdownShown$.next(true);
    setTimeout(() => {
      this.dom.addEventListener('click', this.hideDropdownHandler);
    }, 100);
  }

  private hideDropdownHandler = event => {
    const elem = event.target;
    const isInsideModal = !!elem.closest('.header-dropdown-menu');

    if (!isInsideModal) {
      this.hideProfileDropDown();
    }
  };

  public hideProfileDropDown(): void {
    this.dom.removeEventListener('click', this.hideDropdownHandler);
    this.isSortingDropdownShown$.next(false);
  }

  public filterByCcal() {
    this.dishesList = this.dishesList.sort((a, b) => a.caloricity - b.caloricity);
  }

  public filterByDishTitle() {
    this.dishesList = this.dishesList.sort((a, b) => a.title.localeCompare(b.title));
  }

  public filterByPrice() {
    this.dishesList = this.dishesList.sort((a, b) => a.additionalPrice - b.additionalPrice);
  }

  public selectFilterMethod(filterMethod: string) {
    this.filterMethod = filterMethod;
  }

  public sortDishes() {
    if (this.filterMethod === 'filterByCcal') {
      this.filterByCcal();
    }
    if (this.filterMethod === 'filterByTitle') {
      this.filterByDishTitle();
    }
    if (this.filterMethod === 'filterByPrice') {
      this.filterByPrice();
    }

    const mergedGroupedArray = groupDishesWithSameTitles(this.dishesList);
    const filteredDishes = getIngredientFilteredDishes(
      groupDishesWithSameTitles(mergedGroupedArray),
      this.isIngredientsExclusionEnabled,
      this.excludedIngredientsNames,
    );
    this.dishesCategories = this.filterDishesCategories(filteredDishes);

    this.hideProfileDropDown();

    this.cdr.markForCheck();
  }

  public scrollToCategory(categoryName: string): void {
    this.activeMealTypeTab = categoryName;
    let element = document.getElementById(categoryName);
    element.scrollIntoView({ behavior: 'smooth' });
  }

  public setActiveCategory(isCategoryVisible: boolean, categoryName: string): void {
    if (!isCategoryVisible) {
      return;
    }

    this.activeMealTypeTab = categoryName;
  }

  private filterDishesCategories(filteredDishes) {
    this.dishesCategories = this.getDishesCategories(filteredDishes);
    const sortedCategories = Object.keys(this.dishesCategories)
      .sort((a, b) => DishTypesNumericEnum[a] - DishTypesNumericEnum[b])
      .reduce((acc, key) => {
        acc[key] = this.dishesCategories[key];
        return acc;
      }, {});
    return sortedCategories;
  }

  private getDishesCategories(dishesList: ClientMenuDish[]): DishCategories {
    return dishesList.reduce((prev, curr) => {
      const mealType = curr.mealType;

      if (!prev[mealType]) {
        prev[mealType] = [curr];
        return prev;
      }

      prev[mealType] = [...prev[mealType], curr];
      return prev;
    }, {});
  }

  public isArray(property: any): boolean {
    return Array.isArray(property);
  }

  public closeModal() {
    this.modalCtrl.dismiss();
  }
}
