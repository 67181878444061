import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import {
  ProfileData,
  DeliveryAddressUpdate,
  DeliveryIntervalUpdate, AccountBalance
} from '@shared/models';

@Injectable({
  providedIn: 'root'
})
export class ProfileApiService {

  constructor(
    private http: HttpClient,
  ) { }

  getProfile(): Observable<ProfileData> {
    return this.http.get<ProfileData>(environment.url.profile);
  }

  getBalance(): Observable<AccountBalance> {
    return this.http.get<AccountBalance>(environment.url.account);
  }

  updateProfile(model: ProfileData): Observable<ProfileData> {
    return this.http.put<ProfileData>(environment.url.profile, model);
  }

  updateDeliveryAddress(payload: DeliveryAddressUpdate): Observable<unknown> {
    const url = `${environment.url.profile}/deliveryAddress`;

    return this.http.put<void>(url, payload);
  }

  updateDeliveryInterval(payload: DeliveryIntervalUpdate): Observable<unknown> {
    const url = `${environment.url.profile}/deliveryInterval`;

    return this.http.put<void>(url, payload);
  }

  validateEmail(email: string): Observable<boolean> {
    return this.http.post<boolean>(environment.url.checkEmail, {
      email,
    });
  }

}
