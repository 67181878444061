export enum DishTypesEnum {
  Breakfast = 'Breakfast',
  Brunch = 'Brunch',
  Lunch = 'Lunch',
  Dinner = 'Dinner',
  Supper = 'Supper',
  LunchOrSupper = 'LunchOrSupper',
}

export enum DishTypesNumericEnum {
  'Breakfast',
  'Brunch',
  'Lunch',
  'Dinner',
  'Supper',
}

export enum DishTypesNumericEnumProstoEda {
  'Breakfast',
  'Brunch',
  'LunchOrSupper',
}

export const RuDishTypesEnum = {
  [DishTypesEnum.Breakfast]: 'Завтрак',
  [DishTypesEnum.Brunch]: 'Перекус',
  [DishTypesEnum.Lunch]: 'Обед',
  [DishTypesEnum.Dinner]: 'Полдник',
  [DishTypesEnum.Supper]: 'Ужин',
  [DishTypesEnum.LunchOrSupper]: 'Обед и Ужин',
};
