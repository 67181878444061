import { Pipe, PipeTransform } from '@angular/core';

import { DateWithoutTime } from '@shared/models';
import { RuFullDaysOfWeekEnum, WeekdayNumbersEnum } from '@shared/enums';
import { CommonHelper } from '@shared/utils';
import { MonthNameWordDeclensionPipe } from './month-name-world-declension.pipe';

@Pipe({
  name: 'firstDeliveryDay'
})
export class FirstDeliveryDayPipe implements PipeTransform {
  constructor(
    private commonHelper: CommonHelper,
    private monthNameWordDeclensionPipe: MonthNameWordDeclensionPipe
  ) { }

  transform(date: DateWithoutTime): string {
    const flippedWeekdayNumbersEnum = this.commonHelper.objectFlip(WeekdayNumbersEnum);

    const enDayOfWeek: any = flippedWeekdayNumbersEnum[new Date(`${date.year}/${date.month}/${date.day}`).getDay()];
    const ruDayOfWeek: string = RuFullDaysOfWeekEnum[enDayOfWeek].toLowerCase();

    // Вернёт строку формата '31 июля, пятница'
    return `${date.day} ${this.monthNameWordDeclensionPipe.transform(Number(date.month))}, ${ruDayOfWeek}`;
  }
}
