import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { RudderStackService } from '@shared/services';
import { CommonEvents } from '@shared/enums';
import { TimerService } from '../../services/timer.service';

@Component({
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginContainerComponent {
  isTimeout$ = this.timerService?.timeLeft$.pipe(map(timeLeft => timeLeft > 0));

  constructor(private router: Router, private timerService: TimerService, private rudderstack: RudderStackService) {}

  goToPhoneScreen(): void {
    this.rudderstack.track(CommonEvents.showLoginPhone);
    this.router.navigate(['login']);
  }
}
