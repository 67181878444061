import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@shared/services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  async canActivate(): Promise<boolean> {
    if (await this.authService.doesTokenExist()) {
      return true
    }

    else {
      this.router.navigateByUrl('/login', { skipLocationChange: true });
      return false;
    }
  }
}
