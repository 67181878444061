import { Directive, ElementRef, EventEmitter, Output, AfterViewInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appVisibilityObserver]',
})
export class VisibilityObserverDirective implements AfterViewInit, OnDestroy {
  @Output() visibilityChange: EventEmitter<boolean> = new EventEmitter();

  private observer: IntersectionObserver;

  constructor(private element: ElementRef) {}

  ngAfterViewInit(): void {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        this.visibilityChange.emit(entry.isIntersecting);
      });
    });

    this.observer.observe(this.element.nativeElement);
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
