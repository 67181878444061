import { Component, Input, forwardRef, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-select-variant',
  templateUrl: './select-variant.component.html',
  styleUrls: ['./select-variant.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => SelectVariantComponent),
    },
  ],
})
export class SelectVariantComponent implements ControlValueAccessor {
  @Input() public items: string[] = [];
  @Input() public set value(data: string[]) {
    this.changeInnerValue(data, true);
  }

  public get value(): string[] {
    return this._value;
  }

  @Output() public changeValue = new EventEmitter<string[]>();

  protected onChange: (value) => void;
  protected onTouched: () => void;
  private _value: string[] = [];

  public getIsSelected(item: string): boolean {
    return this.value?.includes(item);
  }

  public toggleItem(item: string): void {
    const isSelected = this.getIsSelected(item);

    if (!isSelected) {
      this.changeInnerValue((this.value || []).concat(item));
      return;
    }

    const updated = (this.value || []).filter(a => a !== item);
    this.changeInnerValue(updated);
  }

  public trackByFn(index): number {
    return index;
  }

  private changeInnerValue(data: string[], isSilent = false): void {
    this._value = data;

    if (!isSilent) {
      this.changeValue.emit(data);
    }

    if (this.onChange) {
      this.onChange(data);
    }

    if (this.onTouched) {
      this.onTouched();
    }
  }

  writeValue(value: string[]): void {
    this._value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

}
