/* eslint-disable max-classes-per-file */

import { LoadingTagEnum } from '@shared/enums';

enum ActionTypes {
  SET_LOADING = '[Loading] Set loading',
  UNSET_LOADING = '[Loading] Unset loading',
}

export class SetLoading {
  static readonly type = ActionTypes.SET_LOADING;
  constructor(public loadingTag: LoadingTagEnum) { }
}

export class UnsetLoading {
  static readonly type = ActionTypes.UNSET_LOADING;
  constructor(public loadingTag: LoadingTagEnum) { }
}
