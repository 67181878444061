import { ChangeDetectionStrategy, Input, Component } from '@angular/core';

import { NgOnDestroyService } from '@shared/services';
import { ClientMenuDish } from '../../models';

interface PackageParams {
  caloricity: number;
  proteins: number;
  fats: number;
  carbs: number;
  amount?: number;
}

@Component({
  selector: 'app-dish-list-header',
  templateUrl: './dish-list-header.component.html',
  styleUrls: ['./dish-list-header.component.scss'],
  providers: [NgOnDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DishListHeaderComponent {

  @Input() public set dishes(payload: ClientMenuDish[]) {
    this.packageParams = this.getPackageParams(payload);
  }

  packageParams: PackageParams;

  private getPackageParams(dishes: ClientMenuDish[] = []): PackageParams {
    if (dishes.length < 1) {
      return null;
    }

    const params: PackageParams = {
      caloricity: 0,
      proteins: 0,
      fats: 0,
      carbs: 0,
    };

    for (const dish of dishes) {
      for (const [key, value] of Object.entries(params)) {
        params[key] = value + dish[key];
      }
    }

    return {
      ...params,
      amount: dishes.length
    };
  }
}
