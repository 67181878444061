<div class="wrapper"
  [ngClass]="{ 'has-packages': this.packagesData?.length }">
  <div class="header">
    <button class="btn-month" (click)="previousMonth()">
      <app-icon class="icon" icon="icon-left"></app-icon>
    </button>
    <div class="header-title">
      {{ month }}<span *ngIf="showYear">, {{ year }}</span>
    </div>
    <button class="btn-month" (click)="nextMonth()">
      <app-icon class="icon" icon="icon-right"></app-icon>
    </button>
  </div>
  <div class="weekdays">
    <div class="item weekday-name">Пн</div>
    <div class="item weekday-name">Вт</div>
    <div class="item weekday-name">Ср</div>
    <div class="item weekday-name">Чт</div>
    <div class="item weekday-name">Пт</div>
    <div class="item weekday-name">Сб</div>
    <div class="item weekday-name">Вс</div>
  </div>
  <div class="month">
    <div *ngFor="let item of dates; trackBy: trackByFn"
      class="item item-day"
      [ngClass]="{
        'is-feed-date': item.isFeedDate,
        immovable: item.isFeedDate && item.packageState === packageStateEnum.delivered,
        movable: item.isFeedDate && item.packageState !== packageStateEnum.delivered,
        'delivery-date': item.isDeliveryDate || item.dateString === highlightedDates[0],
        disabled: item.isDisabled,
        highlighted: highlightedDates.includes(item.dateString),
        dimmed: item.isDimmed
      }"
      (click)="handleDayClick(item)">
      <span class="text">{{ item.displayDate }}</span>
    </div>
  </div>
</div>
