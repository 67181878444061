<div class="wrapper" [class.state-small]="isSmall" [class.is-loading]="isLoading">
  <div class="container subscription-slider">
    <swiper-container
      #swiper
      [speed]="400"
      [centeredSlides]="true"
      [spaceBetween]="10"
      [pagination]="true"
      (swiperslidechange)="onChangeSlide()"
    >
      <swiper-slide *ngFor="let item of subscriptions; trackBy: trackByProperty('subscriptionId')" class="slide-item">
        <app-subscription-item class="subscription-item" [isSmall]="isSmall" [data]="item"></app-subscription-item>
      </swiper-slide>
    </swiper-container>

    <div class="loading-container">
      <ion-spinner name="dots"></ion-spinner>
    </div>
  </div>
</div>
