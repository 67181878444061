import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-dish-params',
  templateUrl: './dish-params.component.html',
  styleUrls: ['./dish-params.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DishParamsComponent {
  @Input() public grams: number;
  @Input() public caloricity: number;
  @Input() public proteins: number;
  @Input() public fats: number;
  @Input() public carbs: number;

  @Input() public isMultiply = false;
}
