<div class="wrapper">
  <div class="modal-header">
    <h2 class="modal-title">Отмена замены</h2>

    <div class="modal-text text--16">
      <p>Вы уверены, что хотите отменить замену?</p>
    </div>
  </div>

  <div class="modal-footer">
    <div class="modal-buttons">
      <button (click)="cancel()" class="btn btn--bordered">Нет</button>
      <button (click)="delete()" class="btn btn-base">Да</button>
    </div>
  </div>
</div>
