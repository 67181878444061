import { SimpleTime } from '@shared/models';

export function getTimeObj(time: string): SimpleTime {
  const [hours, minutes] = time.split(':');

  return {
    hours: parseInt(hours, 10),
    minutes: parseInt(minutes, 10),
  };
}
