import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => RatingComponent),
    },
  ],
})
export class RatingComponent implements ControlValueAccessor {
  @Input() public size: 'small' | 'normal' = 'normal';
  @Input() public isSilent: boolean = false;
  @Input() public set value(data: number) {
    if (data !== this.value) {
      this.changeInnerValue(data, true);
    }
  }

  public get value(): number {
    return this._value;
  }

  @Output() public changeValue = new EventEmitter<number>();
  @Output() public openDishRatingModal = new EventEmitter<number>();

  public readonly items = [5, 4, 3, 2, 1];
  protected onChange: (value) => void;
  protected onTouched: () => void;
  private _value: number;

  public changeRating(data: number, isSilent: boolean): void {
    
    if(!isSilent) {
      this.changeInnerValue(data);
    } else {
      this.openDishRatingModal.emit();
    }
  }

  public trackByFn(index: number): string {
    return `${index}`;
  }

  private changeInnerValue(data: number, isSilent = false): void {
    this._value = data;

    if (!isSilent) {
      this.changeValue.emit(data);
    }

    if (this.onChange) {
      this.onChange(data);
    }

    if (this.onTouched) {
      this.onTouched();
    }
  }

  writeValue(value: number): void {
    this._value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
