
export enum DeliveryZoneEnum {
  free,
  paid,
  noDelivery,
}

export const DeliveryZoneDescription = {
  [DeliveryZoneEnum.free]: 'Адрес входит в зону бесплатной доставки',
  [DeliveryZoneEnum.paid]: 'Доплата за каждую доставку',
  [DeliveryZoneEnum.noDelivery]: 'Адрес находится вне зоны доставки',
}
