import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { ReplacementDiffDishes } from '@store/client-menu';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  setChangeDishesToStorage(subscriptionId: string, diffs: ReplacementDiffDishes[]): void {
    if (diffs?.length) {
      Preferences.set({
        key: subscriptionId,
        value: JSON.stringify(diffs),
      });
    } else {
      Preferences.remove({ key: subscriptionId });
    }
  }

  async getChangeDishesToStorage(subscriptionId: string): Promise<ReplacementDiffDishes[]> {
    const value = (await Preferences.get({ key: subscriptionId })).value;
    return JSON.parse(value) || [];
  }

  async addReplacementDishesToStorage(subscriptionId: string, replaceDish: ReplacementDiffDishes): Promise<void> {
    const changedDishes = await this.getChangeDishesToStorage(subscriptionId);
    changedDishes.push(replaceDish);
    this.setChangeDishesToStorage(subscriptionId, changedDishes);
  }
}
