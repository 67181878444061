import { Pipe, PipeTransform } from '@angular/core';

import { formatDate } from '@shared/utils';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  transform(value: string, displayFormat = null): string {
    return formatDate(value, displayFormat);
  }

}
