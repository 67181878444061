import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Feedback, FeedbackDishRequest, FeedbackToSend } from '../models';

@Injectable({
  providedIn: 'root'
})
export class FeedbackApiService {

  constructor(private http: HttpClient) {}

  public generateDishFeedback(data: FeedbackDishRequest): Observable<Feedback> {
    const url = `${environment.url.feedBack}/generate/dish`;

    return this.http.post<Feedback>(url, data);
  }

  public sendAnswers(feedbackId: string, feedbackToSend: FeedbackToSend): Observable<unknown> {
    const url = `${environment.url.feedBack}/${feedbackId}/answers`;

    return this.http.post<unknown>(url, feedbackToSend);
  }

}
