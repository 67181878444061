import { SimpleTime } from '@shared/models';

function fillTime(value: number): string {
  return value < 10 ? `0${value}` : value + '';
}

function getFormattedTime(data: SimpleTime): string {
  if (!data?.hours) { return ''; }

  return `${fillTime(data.hours)}:${fillTime(data.minutes)}`;
}

export function formatTime(from: SimpleTime, to: SimpleTime): string {
  const start = from?.hours ? `c ${getFormattedTime(from)}` : '';
  const end = to?.hours ? ` до ${ getFormattedTime(to) }`: '';

  return start + end;
}


