<div class="input"
     [ngClass]="{
    'input--error' : !!errorMessage,
    'input--has-value' : !!value,
    'input--custom-message' : customMessage
  }"
     #container>
  <div class="input__wrapper">
    <label [ngClass]="{ 'floating' : placeholderFloat }" class='input__placeholder'>{{ placeholder }}</label>
    <input class="input__area"
           [ngClass]="[customInputClass ? customInputClass : '']"
           [type]="inputType"
           [ngModel]="viewValue"
           #input>

    <div class="error"
         *ngIf="errorMessage"
         [innerHTML]="errorMessage">
    </div>

    <div class="custom-message"
         *ngIf="customMessage && !errorMessage"
         [innerHTML]="customMessage">
    </div>
  </div>

  <div class="suggestions-container">
    <div class="suggestions" *ngIf="suggestions">
      <ng-template *ngIf="suggestions && suggestions.length === 0; else itemsBlock">
        <div class="hint">Неизвестный адрес</div>
      </ng-template>

      <ng-template #itemsBlock>
        <div class="item"
             *ngFor="let item of suggestions"
             (click)="selectSuggestion($event, item)"
             [innerHTML]="item.markedValue"></div>
      </ng-template>
    </div>
  </div>
</div>
