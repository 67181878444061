import { Pipe, PipeTransform } from '@angular/core';
import { getMonthName } from '../utils';

@Pipe({
  name: 'monthNameWordDeclension'
})
export class MonthNameWordDeclensionPipe implements PipeTransform {
  transform(monthNumber: number): string {
    return getMonthName(monthNumber);
  }
}
