export function getCook(cookieName: string): string {
  const cookies = document.cookie;
  const hasCookie = document.cookie && document.cookie.includes(cookieName);

  if (!hasCookie) {
    return null;
  }

  const cookieString = RegExp(cookieName+"=[^;]+").exec(cookies);

  return decodeURIComponent(!!cookieString ? cookieString.toString().replace(/^[^=]+./,'') : '');
}
