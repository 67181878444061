import { Injectable } from '@angular/core';

import { AppmetricaService } from './appmetrica.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private appmetricaService: AppmetricaService) {}

  init(): void {
    this.appmetricaService.init();
  }

  login(): void {
    this.appmetricaService.login();
  }

  purchase(): void {
    this.appmetricaService.purchase();
  }

  profileFilled(): void {
    this.appmetricaService.profileFilled();
  }

  orderCreated(): void {
    this.appmetricaService.orderCreated();
  }
}
