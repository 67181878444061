import { Injectable } from '@angular/core';
import { CacheTypeEnum } from '@shared/enums';

const MINUTE = 60000;
const DEFAULT_DURATION_IN_MINUTES = 10;

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private cache: object = {};
  private cacheDuration: object = {};

  public has(key: string, cacheType: CacheTypeEnum): boolean {
    this.cacheCleanUp(cacheType);

    const cacheSlice = this.cache[cacheType] || {};
    return cacheSlice[key] !== undefined;
  }

  public get<T>(key: string, cacheType: CacheTypeEnum): T | null {
    this.cacheCleanUp(cacheType);

    const cacheSlice = this.cache[cacheType] || {};
    return cacheSlice[key] || null;
  }

  public set(key: string, value: {}, cacheType: CacheTypeEnum, timeInMinutes: number = DEFAULT_DURATION_IN_MINUTES): void {
    this.cacheCleanUp(cacheType);

    if (!this.cache[cacheType]) {
      this.cache[cacheType] = {};
    }

    this.cache[cacheType][key] = value;
    this.setCacheDuration(key, cacheType, timeInMinutes);
  }

  public delete(key: string, cacheType: CacheTypeEnum): void {
    delete this.cacheDuration[cacheType][key];
    delete this.cache[cacheType][key];
  }

  public deleteAllForType(cacheType: CacheTypeEnum): void {
    this.cacheDuration[cacheType] = {};
    this.cache[cacheType] = {};
  }

  public deleteAll(): void {
    this.cacheDuration = {};
    this.cache = {};
  }

  public generateKey(data: object): string {
    return Object.keys(data)
      .map((key: string) => `${key}=${data[key] || 'empty'}`)
      .join('&');
  }

  private setCacheDuration(key: string, cacheType: CacheTypeEnum, time: number = DEFAULT_DURATION_IN_MINUTES): void {
    if (time === 0) { return; }

    if (!this.cacheDuration[cacheType]) {
      this.cacheDuration[cacheType] = {};
    }

    this.cacheDuration[cacheType][key] = Date.now() + time * MINUTE;
  }

  private cacheCleanUp(cacheType: CacheTypeEnum): void {
    const now = Date.now();
    const durationSlice = this.cacheDuration[cacheType] || {};
    const keys = Object.keys(durationSlice) || [];

    keys.forEach((key: string) => {
      if (durationSlice[key] < now) {
        this.delete(key, cacheType);
      }
    });
  }
}
