import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';

import { LoadingStateModel } from './loading.model';
import { SetLoading, UnsetLoading } from './loading.actions';

@State<LoadingStateModel>({
  name: 'loading',
  defaults: {}
})
@Injectable()
export class LoadingState {

  @Action(SetLoading)
  setLoading(ctx: StateContext<LoadingStateModel>, { loadingTag }: SetLoading) {
    if (!loadingTag) { return; }

    ctx.patchState({
      [loadingTag]: true
    });
  }

  @Action(UnsetLoading)
  unsetLoading(ctx: StateContext<LoadingStateModel>, { loadingTag }: UnsetLoading) {
    if (!loadingTag) { return; }

    ctx.patchState({
      [loadingTag]: false
    });
  }

}
