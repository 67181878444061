import { LoginPhoneInputComponent } from './login-phone-input';
import { LoginCodeInputComponent } from './login-code-input';

export const components = [
  LoginPhoneInputComponent,
  LoginCodeInputComponent,
];

export {
  LoginPhoneInputComponent,
  LoginCodeInputComponent,
}
