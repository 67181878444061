import { SimpleTime } from '@shared/models';

export function isValidInterval(
  deliveryTime: string[],
  interval: number,
  fromObj: SimpleTime,
  toObj: SimpleTime
): boolean {
  const fromInMinutes: number = fromObj.hours * 60 + fromObj.minutes;
  const toInMinutes: number = toObj.hours * 60 + toObj.minutes;

  const isFromTimeExist = !!deliveryTime.find((item: string) => item === `${fromObj.hours}:${String(fromObj.minutes).padStart(2, '0')}`);
  const isToTimeExist = !!deliveryTime.find((item: string) => item === `${toObj.hours}:${String(toObj.minutes).padStart(2, '0')}`);

  return isFromTimeExist && isToTimeExist && toInMinutes >= fromInMinutes + interval;
}
