import { DeviceInfo } from '@capacitor/device';
import { Selector } from '@ngxs/store';
import { OSNotification } from 'onesignal-cordova-plugin';

import { NotificationsStateModel } from './notifications.model';

export class NotificationsSelectors {
  @Selector([state => state.notifications])
  static state(state: NotificationsStateModel): NotificationsStateModel {
    return state;
  }

  @Selector([NotificationsSelectors.state])
  static lastNotification(state: NotificationsStateModel): OSNotification {
    return state.lastNotification;
  }

  @Selector([NotificationsSelectors.state])
  static deviceInfo(state: NotificationsStateModel): DeviceInfo {
    return state.deviceInfo;
  }
}
