import { ClientMenuDish } from '@modules/client-menu/models';

export function hasExcludedIngredient(dish: ClientMenuDish, ingredientName: string): boolean {
  return dish.ingredientCategories.some(category => category === ingredientName);
}

export function hasExcludedIngredients(dish: ClientMenuDish, excludedIngredients: string[]): boolean {
  return dish.ingredientCategories.some(category => excludedIngredients.some(ingredient => category === ingredient));
}

export function getIngredientFilteredDishes(
  dishes: ClientMenuDish[],
  isExclusionEnabled: boolean,
  excludedIngredients: string[]
): ClientMenuDish[] {
  if (!isExclusionEnabled) { return dishes; }

  return dishes.filter(dish => !hasExcludedIngredients(dish, excludedIngredients));
}
