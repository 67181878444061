import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginCodeInputComponent, LoginPhoneInputComponent } from './components';
import { LoginContainerComponent } from './containers';

const routes: Routes = [
  {
    path: '',
    component: LoginContainerComponent,
    children: [
      {
        path: 'phone',
        component: LoginPhoneInputComponent,
      },
      {
        path: 'code',
        component: LoginCodeInputComponent,
      },
      {
        path: '**',
        redirectTo: 'phone',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginRoutingModule {}
