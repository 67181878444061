import { Component, Input } from '@angular/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { HttpClient } from '@angular/common/http';
import { Device } from '@capacitor/device';

@Component({
  selector: 'app-payment-split',
  templateUrl: './payment-split.component.html',
  styleUrls: ['./payment-split.component.scss'],
  providers: [InAppBrowser],
})
export class PaymentSplitComponent {
  @Input() isSkipped = false;
  @Input() weekSplitPrice = 0;
  @Input() total = 0;
  @Input() discount = 0;

  constructor(private http: HttpClient, private iab: InAppBrowser) {}

  async openExternalLink(url: string) {
    const { platform } = await Device.getInfo();

    if (platform === 'web') {
      window.open(url, '_blank');
      return;
    }
    try {
      const pdfBlob = await this.http.get(url, { responseType: 'blob' }).toPromise();
      const reader = new FileReader();
      reader.readAsDataURL(pdfBlob);
      reader.onloadend = async () => {
        const base64data = reader.result as string;

        this.iab.create(base64data, '_blank', { location: 'no' });
      };
    } catch (error) {
      console.error('Error opening PDF file:', error);
    }
  }
}
