import { Dayjs } from 'dayjs';

import {
  IntWeekdaysToFullRussianEnum,
  IntWeekdaysToFullRussianGenEnum,
  IntWeekdaysToRussianEnum,
  RuMonthsNamesEnum
} from '@shared/enums';

export const getRuDay = function(date: Dayjs): string {
  return Object.values(IntWeekdaysToRussianEnum)[date.day()] as string;
}

export const getFullRuDay = function(date: Dayjs): string {
  return Object.values(IntWeekdaysToFullRussianEnum)[date.day()] as string;
}

export const getFullRuGenDay = function(date: Dayjs): string {
  return Object.values(IntWeekdaysToFullRussianGenEnum)[date.day()] as string;
}

export const getRuMonth= function(date: Dayjs): string {
  return Object.values(RuMonthsNamesEnum)[date.day()] as string;
}
