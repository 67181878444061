import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutContainerComponent } from './containers';
import { AuthGuard } from '@shared/guards';

const routes: Routes = [
  {
    path: '',
    component: LayoutContainerComponent,
    children: [
      {
        path: 'create',
        loadChildren: () => import('../create-subscription/create-subscription.module').then(m => m.CreateSubscriptionModule),
      },
      {
        path: 'subscriptions',
        loadChildren: () => import('../client-subscriptions/client-subscriptions.module').then(m => m.ClientSubscriptionsModule),
        canActivate: [() => inject(AuthGuard).canActivate()],
      },
      {
        path: 'menu',
        loadChildren: () => import('../client-menu/client-menu.module').then(m => m.ClientMenuModule),
        canActivate: [() => inject(AuthGuard).canActivate()],
      },
      {
        path: 'profile',
        loadChildren: () => import('../profile/profile.module').then(m => m.ProfileModule),
        canActivate: [() => inject(AuthGuard).canActivate()],
      },
      {
        path: 'notifications',
        loadChildren: () => import('../notifications/notifications.module').then(m => m.NotificationsModule),
        canActivate: [() => inject(AuthGuard).canActivate()],
      },
      {
        path: 'thanks',
        loadChildren: () => import('../thanks/thanks.module').then(m => m.ThanksModule),
      },
      {
        path: '**',
        redirectTo: 'subscriptions'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule {}
