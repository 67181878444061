import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanMatch, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { AuthService } from '@shared/services';

@Injectable({
  providedIn: 'root'
})
export class AutoLoginGuard implements CanMatch {

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  async canMatch(): Promise<boolean> {

    if (await this.authService.doesTokenExist()) {
      this.router.navigate(['/cabinet']);
      return false;
    }

    else {
      return true;
    }
  }

}
