import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { EMPTY, Observable } from 'rxjs';

import { ReplacementDiff, ClientMenuSelectors, ReplacementDiffDishes } from '@store/client-menu';
import { ClientMenuDish, ConfirmReplacementResponse, PackageCustomizations } from '@modules/client-menu/models';
import { ClientMenuApiService } from '@modules/client-menu/services';
import { ClientSubscriptionPackage } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class ClientMenuActionsService {
  constructor(private store: Store, private apiService: ClientMenuApiService) {}

  public confirmCustomization(skipCustomizationErrors = false): Observable<ConfirmReplacementResponse> {
    const { activeSubscriptionDetails, changedDish } = this.store.selectSnapshot(ClientMenuSelectors.state);

    if (!activeSubscriptionDetails) {
      return EMPTY;
    }

    const changes = changedDish[activeSubscriptionDetails.subscriptionId];
    const packageIdList = new Set(changes.map(diff => diff.packageId));
    const packageCustomizations: PackageCustomizations[] = [];

    packageIdList.forEach(packageId => {
      const changesByPackage = changes.filter(diff => diff.packageId === packageId);
      const currentPackage = activeSubscriptionDetails.packageItems.find(p => p.packageId === packageId);

      packageCustomizations.push({
        packageId,
        menuItems: currentPackage.menuItems.map(item => ({
          dishCode: item.id,
          originalDishCode: item.isAdded
            ? null
            : item.shouldBeConfirmed && changesByPackage.find(diff => diff.newDish.originalUuid === item.originalUuid).originalDish !== null
            ? changesByPackage.find(diff => diff.newDish.originalUuid === item.originalUuid).originalDish.id || null
            : item.id,
          mealType: item.mealType,
        })),
      });
    });

    const requestData = {
      skipCustomizationErrors,
      packageCustomizations,
    };

    return this.apiService.customizeDishes(activeSubscriptionDetails.subscriptionId, requestData);
  }
}
