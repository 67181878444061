import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastController: ToastController) {}

  public async presentToastError(position: 'top' | 'middle' | 'bottom', error: HttpErrorResponse) {
    const toast = await this.toastController.create({
      header: 'Ошибка ' + error.status,
      message: error?.error?.error || 'Неизвестная ошибка',
      duration: 3000,
      position: position,
      color: error.status >= 500 ? 'danger' : 'warning',
      icon: 'alert-circle',
      mode: 'ios',
    });

    await toast.present();
  }

  public async presentToastSuccess(position: 'top' | 'middle' | 'bottom', header: string, message: string = '') {
    const toast = await this.toastController.create({
      header,
      message,
      duration: 3000,
      position,
      color: 'success',
      icon: 'checkmark',
      mode: 'ios',
    });

    await toast.present();
  }
}
