import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';

import { environment } from 'src/environments/environment';
import { RegionsEnum } from '@shared/enums';

@Injectable({
  providedIn: 'root'
})
export class CoordinatesService {

  public msk$ = new ReplaySubject<string[][][]>(1);
  public mo$ = new ReplaySubject<string[][][]>(1);

  constructor(private http: HttpClient) {
    this.initCoordinates();
  }

  initCoordinates(): void {
    combineLatest([this.get(RegionsEnum.MSK), this.get(RegionsEnum.MO)])
      .subscribe(([msk, mo]: [string[][][], string[][][]]) => {
        this.msk$.next(msk);
        this.mo$.next(mo);
      });
  }

  public get(id: RegionsEnum): Observable<string[][][]> {
    const url = `${environment.url.map}/${id}-coords`;

    return this.http.get<string[][][]>(url);
  }

}
