import { Directive, ElementRef, Renderer2, OnInit } from '@angular/core';
import { Device } from '@capacitor/device';

@Directive({
  selector: '[appIosDeviceCheck]',
})
export class IosDeviceCheckDirective implements OnInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  async ngOnInit() {
    const info = await Device.getInfo();

    if (info.platform === 'ios') {
      this.applyStylesIfNeeded(info.model);
    }
  }

  private applyStylesIfNeeded(model: string) {
    const olderDevices = ['iPhone6', 'iPhone7', 'iPhone8', 'iPhone14,6'];

    if (olderDevices.some(device => model.includes(device))) {
      this.renderer.setStyle(this.el.nativeElement, 'padding-bottom', '10px');
    }
  }
}
