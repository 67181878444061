import { Pipe, PipeTransform } from '@angular/core';

import { PE_DESCRIPTION, ProgramCaloriesEnum } from '@shared/enums';

@Pipe({
  name: 'programDescription',
})
export class ProgramDescriptionPipe implements PipeTransform {
  transform(value: ProgramCaloriesEnum | string): string {
    if (!value) {
      return '';
    }

    return PE_DESCRIPTION[value] || '';
  }
}
