export enum CommonEventsEnum {
  pageLoaded = 'PAGE_LOADED',
  pageChange = 'PAGE_CHANGE',
}

export enum ProgramBlockEventsEnum {
  programParamsChanged = 'PROGRAM_BLOCK_PARAMS_CHANGED',
}

export enum ClientMenuEventsEnum {
  ingredientsInterest = 'REPLACEMENT__INGREDIENTS_INTEREST_EXPRESSED',
  dishSelected = 'REPLACEMENT__DISH_SELECTED',
  dishPopupClosed = 'REPLACEMENT__DISH_SELECTION_POPUP_CLOSED',
  newDishSelected = 'REPLACEMENT__NEW_DISH_SELECTED',
  noAvailableDishes = 'REPLACEMENT__AVAILABLE_DISHES_NOT_FOUND',
  dateClick = 'REPLACEMENT__DATE_CLICK',
  paymentBtnClick = 'REPLACEMENT__PAYMENT_BUTTON_CLICK',
  dishCanceled = 'REPLACEMENT__DISH_CANCELED',
  resetBtnClick = 'REPLACEMENT__RESET_BUTTON_CLICK',
  noSubscriptions = 'REPLACEMENT__CLIENT_NO_SUBSCRIPTIONS',
  chooseSubscriptionClick = 'REPLACEMENT__CHOOSE_SUBSCRIPTION_BUTTON_CLICK',
  confirmationPopupConfirm = 'REPLACEMENT__CONFIRMATION_POPUP_CONFIRM',
  confirmationPopupReturn = 'REPLACEMENT__CONFIRMATION_POPUP_RETURN',
  successfulPayment = 'REPLACEMENT__SUCCESSFUL_PAYMENT',
  openSorting = 'REPLACEMENT__OPEN_SORTING_MODAL',
  changeSort = 'REPLACEMENT_SORT_CHANGED',
  addDishBtnClick = 'CLIENT_MENU__ADD_DISH_BUTTON_CLICK',
  additionalDishSelected = 'CLIENT_MENU__ADDITIONAL_DISH_SELECTED',
  dishAdditionPopupClosed = 'CLIENT_MENU__DISH_ADDITION_POPUP_CLOSED',
  dishAdditionCanceled = 'CLIENT_MENU__DISH_ADDITION_CANCELED',
}

export enum RenewalEventsEnum {
  renewalButtonClick = 'RENEWAL__RENEWAL_BTN_CLICK',
}

export enum PaymentEventsEnum {
  newCard = 'NEW_CARD_PAYMENT_CLICK',
  savedCard = 'SAVED_CARD_PAYMENT_CLICK',
  sbpPayment = 'SBP_PAYMENT',
  successfulPayment = 'SUCCESSFUL_PAYMENT',
}

export enum OrderEventsEnum {
  orderBtnClick = 'ORDER_BUTTON_CLICK',
  paymentMethodChanged = 'PAYMENT_METHOD_CHANGED',
  createSubscriptionClick = 'CREATE_SUBSCRIPTION_CLICK',
  deleteSubscription = 'DELETE_SUBSCRIPTION',
}

export enum CheckoutEventsEnum {
  checkoutParamChange = 'CHECKOUT_PARAM_CHANGED',
  formDataChanged = 'FORM_DATA_CHANGED',
  checkoutModalOrderButton = 'CHECKOUT_MODAL_ORDER_BUTTON_CLICK',
  setPromocodeInput = 'SET_PROMOCODE_INPUT',
}

export enum ProfileEventsEnum {
  profileDataChanged = 'PROFILE_DATA_CHANGED',
  profileSavedBtnClick = 'PROFILE_SAVED_BTN_CLICK',
  profileDeleteBtnClick = 'PROFILE_DELETE_BTN_CLICK',
  profileDeleteModalConfirmed = 'PROFILE_DELETE_MODAL_CONFIRMED',
  profileDeleteModalCanceled = 'PROFILE_DELETE_MODAL_CANCELED',
}

export enum ContactsEventsEnum {
  profileOfertaBtnClick = 'PROFILE_OFERTA_BTN_CLICK',
  phoneIconClick = 'PHONE_ICON_CLICK',
  telegramIconClick = 'TELEGRAM_ICON_CLICK',
  whatsappIconClick = 'WHATSAPP_ICON_CLICK',
}

export enum AuthEventEnum {
  login = 'LOGIN',
  logout = 'LOGOUT',
  sendSmsCode = 'SEND_SMS_CODE',
}
