import { ProgramCaloriesEnum } from './program-calories.enum';

export enum ProgramTypesEnum {
  Sport = 'Sport',
  Fit = 'Fit',
  Life = 'Life',
  Veg = 'Veg',
}

export enum PETypesEnum {
  PE_3 = 'PE_3',
  PE_4 = 'PE_4',
  PE_5 = 'PE_5',
  PE_Plus = 'PE_Plus',
}

export enum PEProductCodeEnum {
  SE1200 = 'SE1200',
  SE1500 = 'SE1500',
  SE2000 = 'SE2000',
  SE2500 = 'SE2500',
}

export const PE_TYPE_NAMES = {
  [ProgramCaloriesEnum.cal1200]: '3 приёма',
  [ProgramCaloriesEnum.cal1500]: '4 приёма',
  [ProgramCaloriesEnum.cal2000]: '5 приёмов',
  [ProgramCaloriesEnum.cal2500]: 'ПростоЕда+',
};

export const PE_DESCRIPTION = {
  [ProgramCaloriesEnum.cal1200]: 'Готовое питание по цене продуктовой корзины',
  [ProgramCaloriesEnum.cal1500]: 'Домашняя еда с большим количеством овощей ',
  [ProgramCaloriesEnum.cal2000]: 'Все уже продумано – нужно только разогреть',
  [ProgramCaloriesEnum.cal2500]: 'Больше разнообразия и вкуса',
};

export const PE_TYPES_LIST = [
  ProgramCaloriesEnum.cal1200,
  ProgramCaloriesEnum.cal1500,
  ProgramCaloriesEnum.cal2000,
  ProgramCaloriesEnum.cal2500,
];

export const PE_BY_PRODUCT_CODE = {
  [PEProductCodeEnum.SE1200]: ProgramCaloriesEnum.cal1200,
  [PEProductCodeEnum.SE1500]: ProgramCaloriesEnum.cal1500,
  [PEProductCodeEnum.SE2000]: ProgramCaloriesEnum.cal2000,
  [PEProductCodeEnum.SE2500]: ProgramCaloriesEnum.cal2500,
};

export enum ProgramDurationsEnum {
  oneDay = 'oneDay',
  twoDays = 'twoDays',
  threeDays = 'threeDays',
  oneWeek = 'oneWeek',
  twoWeeks = 'twoWeeks',
  threeWeeks = 'threeWeeks',
  fourWeeks = 'fourWeeks',
  twoMonth = 'twoMonth',
}

export const ProgramDurationInDays = {
  [ProgramDurationsEnum.oneDay]: 1,
  [ProgramDurationsEnum.twoDays]: 2,
  [ProgramDurationsEnum.oneWeek]: 5,
  [ProgramDurationsEnum.twoWeeks]: 10,
  [ProgramDurationsEnum.threeWeeks]: 15,
  [ProgramDurationsEnum.fourWeeks]: 20,
};

export const ProgramDurationNames = {
  [ProgramDurationsEnum.oneDay]: '1 день',
  [ProgramDurationsEnum.twoDays]: '2 дня',
  [ProgramDurationsEnum.threeDays]: '3 дня',
  [ProgramDurationsEnum.oneWeek]: '1 неделя',
  [ProgramDurationsEnum.twoWeeks]: '2 недели',
  [ProgramDurationsEnum.threeWeeks]: '3 недели',
  [ProgramDurationsEnum.fourWeeks]: '4 недели',
  [ProgramDurationsEnum.twoMonth]: '2 месяца',
};

export const ProgramDurationNamesMobile = {
  [ProgramDurationsEnum.oneDay]: '1 день',
  [ProgramDurationsEnum.twoDays]: '2 дня',
  [ProgramDurationsEnum.threeDays]: '3 дня',
  [ProgramDurationsEnum.oneWeek]: '1 нед.',
  [ProgramDurationsEnum.twoWeeks]: '2 нед.',
  [ProgramDurationsEnum.threeWeeks]: '3 нед.',
  [ProgramDurationsEnum.fourWeeks]: '4 нед.',
  [ProgramDurationsEnum.twoMonth]: '2 месяца',
};

export enum ProgramModesEnum {
  NoBreakfast = 'NoBreakfast',
  Full = 'Full',
  IdealDay = 'IdealDay',
  ExperimentalWeek = 'ExperimentalWeek',
}
