import { Injectable } from '@angular/core';
import { Appmetrica } from 'capacitor-appmetrica';

import { environment } from 'src/environments/environment';
import { AnalyticsEventEnum } from '@shared/enums';

@Injectable({
  providedIn: 'root'
})
export class AppmetricaService {

  constructor(
    private appmetrica: Appmetrica
  ) {}

  init(): void {
    if (!environment.production) {
      return;
    }

    this.appmetrica.activate(environment.appMetricaId, { logs: true })
  }

  login(): void {
    if (!environment.production) {
      return;
    }

    this.appmetrica.reportEvent(AnalyticsEventEnum.login, {});
  }

  purchase(): void {
    if (!environment.production) {
      return;
    }

    this.appmetrica.reportEvent(AnalyticsEventEnum.purchase, {});
  }

  orderCreated(): void {
    if (!environment.production) {
      return;
    }

    this.appmetrica.reportEvent(AnalyticsEventEnum.orderCreated, {});
  }

  profileFilled(): void {
    if (!environment.production) {
      return;
    }

    this.appmetrica.reportEvent(AnalyticsEventEnum.profileFilled, {});
  }
}
