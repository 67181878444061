import { Pipe, PipeTransform } from '@angular/core';
import { ProgramDurationsEnum } from '@shared/enums';
import { AvailableDiscount } from '@shared/models';

@Pipe({
  name: 'availableDiscount',
})
export class AvailableDiscountPipe implements PipeTransform {
  transform(availableDiscounts: AvailableDiscount[], durationId: ProgramDurationsEnum): number {
    const availableDaysCount = [];

    switch (durationId) {
      case ProgramDurationsEnum.twoDays:
        availableDaysCount.push(2);
        break;

      case ProgramDurationsEnum.oneWeek:
        availableDaysCount.push(5, 6, 7);
        break;

      case ProgramDurationsEnum.twoWeeks:
        availableDaysCount.push(10, 12, 14);
        break;

      case ProgramDurationsEnum.fourWeeks:
        availableDaysCount.push(20, 24, 28);
        break;

      case ProgramDurationsEnum.twoMonth:
        availableDaysCount.push(40, 48, 56);
        break;

      default:
        break;
    }

    const discount = availableDiscounts.find(({ daysCount }) => availableDaysCount.includes(daysCount))?.discountPercentage || 0;

    return discount;
  }
}
