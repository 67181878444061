import { Injectable, PLATFORM_ID, Inject } from '@angular/core';

import { DateWithoutTime, SubscriptionInfo, SubscriptionInfoForThanksPage } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class CommonHelper {
  constructor() {}

  public getCurrentDateObjWithoutTime(date?: Date): DateWithoutTime {
    const tempDate = date ? new Date(date) : new Date();
    return {
      day: tempDate.getDate(),
      month: tempDate.getMonth() + 1,
      year: tempDate.getFullYear(),
    } as DateWithoutTime;
  }

  public getIsoCurrentDateStrWithTime(date?: Date): string {
    const tempDate = date ? new Date(date) : new Date();

    return tempDate.toISOString();
  }

  public getIsoCurrentDateStrWithEmptyTime(date?: Date): string {
    const tempDate = date ? new Date(date) : new Date();

    return `${tempDate.toISOString().split('T')[0]}T00:00:00.000Z`;
  }

  // Дату нужно привести к такому формату: '2020-06-11T00:00:00'
  public getCurrentDateStrWithEmptyTime(date?: Date): string {
    const tempDate = date ? new Date(date) : new Date();
    const currentDateObjWithoutTime = this.getCurrentDateObjWithoutTime(tempDate);

    return (
      `${String(currentDateObjWithoutTime.year).padStart(2, '0')}` +
      `-${String(currentDateObjWithoutTime.month).padStart(2, '0')}` +
      `-${String(currentDateObjWithoutTime.day).padStart(2, '0')}T00:00:00`
    );
  }

  public objectFlip(obj: object): object {
    return Object.keys(obj).reduce((ret, key) => {
      ret[obj[key]] = key;
      return ret;
    }, {});
  }

  public capitalize(str: string): string {
    if (!str) {
      return '';
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  public cleanObject<T>(model: T): T {
    const obj: T = { ...model };

    Object.keys(obj).forEach(key => {
      const value = obj[key];
      const hasProperties = value && Object.keys(value).length > 0;

      if (value === null || value === undefined) {
        delete obj[key];
      } else if (typeof value !== 'string' && hasProperties) {
        this.cleanObject(value);
      }
    });

    return obj;
  }

  public mapSubscriptionInfo(info: SubscriptionInfo): SubscriptionInfoForThanksPage {
    if (!info) {
      return null;
    }

    return {
      programTypeName: info.menuType,
      programDailyCalories: info.size,
      firstDeliveryDay: this.getCurrentDateObjWithoutTime(new Date(this.getCurrentDateStrWithEmptyTime(new Date(info.startDate)))),
      deliverySchedule: {
        daysOfWeek: info.daysOfWeek,
        time: {
          from: `${info.deliveryTimeFrom.hours}:${this.getDoubleDigitString(info.deliveryTimeFrom.minutes)}`,
          to: `${info.deliveryTimeTo.hours}:${this.getDoubleDigitString(info.deliveryTimeTo.minutes)}`,
        },
      },
      address: info.address,
      paymentMethod: info.paymentMethod !== 'NotSet' ? info.paymentMethod : null,
    } as SubscriptionInfoForThanksPage;
  }

  private getDoubleDigitString(val: number): string {
    return val < 10 ? val + '0' : val.toString();
  }
}
