import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import { SharedModule } from '@shared/shared.module';
import { containers } from './containers';
import { components } from './components';
import { TimerService } from './services/timer.service';

@NgModule({
  declarations: [
    ...containers,
    ...components,
  ],
  imports: [
    CommonModule,
    SharedModule,
    LoginRoutingModule,
  ],
  providers: [TimerService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LoginModule {
}
