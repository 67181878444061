import { Pipe, PipeTransform } from '@angular/core';

import { PE_TYPE_NAMES, ProgramCaloriesEnum } from '@shared/enums';

@Pipe({
  name: 'programName',
})
export class ProgramNamePipe implements PipeTransform {
  transform(value: ProgramCaloriesEnum | string): string {
    if (!value) {
      return '';
    }

    return PE_TYPE_NAMES[value] || '';
  }
}
