import dayjs from 'dayjs';

import { DateObj } from '@shared/models';

export function getDateObj(date: string): DateObj {
  return {
    year: dayjs(date).get('year'),
    month: dayjs(date).get('month') + 1,
    day: dayjs(date).get('date')
  };
}
