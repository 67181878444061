<div class="container content code">
  <form class="form form-code" [ngClass]="{ 'locked': isSendingCode$ | async }" (submit)="checkVerificationCode()">
    <div class="form-label">Код проверки</div>

    <app-text-input
      class="form-input"
      [formControl]="verificationCodeControl"
      customInputClass="send-code-input"
      inputType="tel"
      inputId="smsCode"
      autocomplete="on"
      [errorMessages]="{
        required: codeErrorMessage,
        mask: codeErrorMessage,
        authCodeError: authErrorMessage$ | async
      }"
      mask="0000"
    ></app-text-input>
  </form>

  <div class="hint-container">
    <div class="text-left" *ngIf="isTimeout$ | async; else btnResend">Отправить повторно через 0:{{ secondsLeft$ | async }}</div>

    <ng-template #btnResend>
      <div class="text-center">
        <button class="resend-code-btn" (click)="resendVerificationCode()">Отправить код повторно</button>
      </div>
    </ng-template>
  </div>

  <ng-container *ngIf="codeError$ | async">
    <p class="error-msg">{{ codeError$ | async }}</p>
  </ng-container>
</div>
