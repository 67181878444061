import { Selector } from "@ngxs/store";

import { AuthStateModel } from "./auth.state";

export class AuthSelectors {
  @Selector([state => state.auth])
  static state(state: AuthStateModel): AuthStateModel {
    return state;
  }

  @Selector([AuthSelectors.state])
  static isAuthorized(state: AuthStateModel): boolean {
    return state.isAuthorized;
  }

  @Selector([AuthSelectors.state])
  static inputPhone(state: AuthStateModel): string {
    return state.inputPhone;
  }

  @Selector([AuthSelectors.state])
  static isGettingCode(state: AuthStateModel): boolean {
    return state.isGettingCode;
  }

  @Selector([AuthSelectors.state])
  static isSendingCode(state: AuthStateModel): boolean {
    return state.isSendingCode;
  }

  @Selector([AuthSelectors.state])
  static codeError(state: AuthStateModel): string {
    return state.codeError;
  }

  @Selector([AuthSelectors.state])
  static authErrorMessage(state: AuthStateModel): string {
    return state.authErrorMessage;
  }

  @Selector([AuthSelectors.state])
  static notificationsAgreement(state: AuthStateModel): boolean {
    return state.notificationsAgreement;
  }

  @Selector([AuthSelectors.state])
  static privacyAgreement(state: AuthStateModel): boolean {
    return state.privacyAgreement;
  }
}
