<ion-router-outlet [swipeGesture]="false"></ion-router-outlet>

<ion-tabs>
  <ion-tab-bar mode="ios" appIosDeviceCheck>
    <ion-tab-button [disabled]="!(subscriptionList$ | async)?.length" tab="subscriptions">
      <ion-icon name="star"></ion-icon>
      Подписки
    </ion-tab-button>
    <ion-tab-button [disabled]="!(subscriptionList$ | async)?.length" tab="menu">
      <ion-icon name="restaurant"></ion-icon>
      Мое меню
    </ion-tab-button>
    <ion-tab-button tab="create">
      <ion-icon class="large" name="add-circle"></ion-icon>
    </ion-tab-button>
    <ion-tab-button tab="notifications">
      <ion-icon name="notifications"></ion-icon>
      Уведомления
    </ion-tab-button>
    <ion-tab-button tab="profile">
      <ion-icon name="person"></ion-icon>
      Профиль
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
