import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { environment } from 'src/environments/environment';
import {
  ClientMenuDish,
  ReplacementInSubscription,
  ConfirmReplacementResponse
} from '../models';

@Injectable({
  providedIn: 'root'
})
export class ClientMenuApiService {

  constructor(
    private http: HttpClient
  ) { }

  public getReplacementDishes(packageId: string, dishId: string): Observable<ClientMenuDish[]> {
    return this.http.get<ClientMenuDish[]>(environment.url.subscription + `/${packageId}/dishes-for-replacement/${dishId}`);
  }

  public customizeDishes(subscriptionId: string, data: ReplacementInSubscription): Observable<ConfirmReplacementResponse> {
    return this.http.post<ConfirmReplacementResponse>(environment.url.subscription + `/${subscriptionId}/customize`, data);
  }

  public cancelCustomization(paymentId: string): Observable<any> {
    return this.http.post<any>(environment.url.subscription + `/${paymentId}/cancel-customization`, {});
  }

  public checkReplacementAvailability(): Observable<boolean> {
    return of(false) // this.http.get<boolean>(environment.url.isReplacementAvailable);
  }

  public getAdditionalDishes(packageId: string): Observable<ClientMenuDish[]> {
    return this.http.get<ClientMenuDish[]>(environment.url.subscription + `/${packageId}/additional-dishes`);
  }

}
