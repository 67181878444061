import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { PipesModule } from './pipes';
import { components } from './components';
import { directives } from './directives';

const modules = [ReactiveFormsModule, FormsModule, RouterModule, IonicModule, PipesModule];

@NgModule({
  declarations: [...components, ...directives],
  imports: [...modules, CommonModule, NgxMaskDirective, NgxMaskPipe],
  exports: [...modules, ...components, ...directives],
  providers: [provideNgxMask()],
})
export class SharedModule {}
