import { Component } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  templateUrl: 'cancel-modal.component.html',
  styleUrls: ['cancel-modal.component.scss']
})
export class CancelModalComponent {

  constructor(
    private modalCtrl: ModalController,
  ) {}

  public cancel(): void {
    this.modalCtrl.dismiss(null, 'cancel');
  }

  public delete(): void {
    this.modalCtrl.dismiss(true, 'delete');
  }
}
