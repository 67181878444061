<div class="wrapper" (click)="change(!value)">
  <div class="left">
    <ng-content></ng-content>
  </div>
  <div class="right">
    <div [class]="'inner ' + color" [ngClass]="{ 'state-active':  value }">
      <div class="control"></div>
    </div>
  </div>
</div>
