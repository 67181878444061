import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

import { ProfileData } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class SentryService {
  public setUser(profile: ProfileData): void {
    profile
      ? Sentry.setUser({ id: profile.id, email: profile.email, username: profile.lastName + ' ' + profile.firstName, phone: profile.phone })
      : Sentry.setUser(null);
  }

  public sendEvent(title: string, data: { [key: string]: string }): void {
    Sentry.withScope(scope => {
      for (const [key, value] of Object.entries(data)) {
        scope.setTag(key, value);
        Sentry.captureMessage(title);
      }
    });
  }
}
