/**
 * Возвращает слово в правильном склонении
 * @param num число вещей, в соответствии с которым надо просклонять
 * @param singular слово в единственном числе
 * @param pluralSmall слово во множественном числе от 2 до 4
 * @param pluralBigger слово во множественном числе от 5 и более
 */
export function pluralize(num: number, singular: string, pluralSmall: string, pluralBigger: string): string {
  if (num === null || num === undefined || isNaN(num) || num < 0) { return null; }

  if (num === 1 || (num > 20 && num % 10 === 1)) { return singular; }
  if ((num < 5 || num > 15) && num % 10 < 5 && num % 10 !== 0 && num !== 0) { return pluralSmall; }

  return pluralBigger;
}
